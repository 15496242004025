import React from 'react';

let termsOfUse = (
    <div>
            &emsp;A Petrobras o convida a visitar o site do Projeto de Monitoramento da Paisagem Acústica Submarina da Bacia de Santos (PMPAS-BS) e informa os Termos e Condições que regem a utilização do mesmo. Consideramos que, ao utilizá-lo, você estará manifestando concordância com estes Termos e Condições.
            <br/>
            &emsp;O conteúdo deste Portal se destina a oferecer aos usuários da internet informações sobre o PMPAS-BS.
            <br/>
            &emsp;O site do PMPAS-BS é um serviço oferecido por Petróleo Brasileiro S.A. por meio do Instituto de Pesquisa da Marinha do Brasil (IPqM), responsável pelo seu desenvolvimento, manutenção e disponibilização.
            <br/>
        <b>Definições</b>
            <ul>   
                <li>Termos e condições de uso: regras estabelecidas para o uso do Portal;</li>
                <li>Usuário: pessoa física ou jurídica que faz uso do Portal, mediante cadastro, e dos links ali disponibilizados;</li>
                <li>Portal: designa o endereço <a href="(http://www.simmao.pmpabs.eco.br/">www.simmao.pmpabs.eco.br</a>.</li>
                <li>Cadastro de usuários: dados pessoais fornecidos pelo usuário, cuja veracidade é de responsabilidade deste.</li>
            </ul>

        <b>Informação</b>
            <br/>
            &emsp;A Petrobras se esforçará para manter as informações e materiais contidos neste Portal tão precisos, atualizados e completos quanto possível. No entanto, não se responsabilizará pela utilização, aplicação ou processamento que os usuários possam dar à mencionada informação. O usuário é inteiramente responsável pela finalidade de uso do dado coletado no Portal.
            <br/>
            &emsp;A Petrobras procura assegurar que as informações fornecidas sejam acuradas, completas, atuais e que o seu uso não tenha interrupções nem erros. O conteúdo, incluindo todas as informações disponíveis no Portal ou acessadas por meio dele, é fornecido "Como Encontrado". A utilização deste material, bem como qualquer interpretação sobre o mesmo ou inserção em outros contextos são de responsabilidade exclusiva do usuário.
            <br/>
            &emsp;A informação contida neste Portal poderá ser atualizada ou modificada periodicamente. Por conseguinte, não deve ser interpretada como definitiva.
            <br/>
        
        <b>Privacidade de nossos visitantes</b>
            <br/>
            &emsp;A Petrobras não tem a responsabilidade de controlar a utilização que os usuários fazem do Portal, assim como o acesso aos links nele indicados.
            <br/>
            &emsp;Em particular, a Petrobras não garante que os usuários utilizem o Portal em conformidade com a Lei, com os presentes Termos e Condições de uso, com a moral e os bons costumes, os princípios gerais de direito e a ordem pública, nem que o façam de forma cuidadosa e prudente, atendendo ao dever objetivo de cuidado.
            <br/>
            &emsp;A Petrobras não se responsabiliza pelos danos e prejuízos de qualquer natureza que possam advir da utilização do Portal e dos seus serviços na forma acima descrita.
            <br/>

        <b>Do Usuário</b>
            <br/>
            &emsp;O usuário responderá pelos danos e prejuízos de qualquer natureza que a Petrobras possa sofrer em consequência do descumprimento de qualquer uma das obrigações a que fica submetido por estes Termos e Condições Gerais de Uso.
            <br/>

        <b>Utilização do Portal e de seus links</b>
            <br/>
            &emsp;Em nenhuma hipótese será a Petrobras responsável pelo uso de seu Portal ou pelo acesso a links nele indicados, bem como por atos praticados por usuário que tenham por base informações obtidas no Portal e em links. A Petrobras não se responsabiliza nem pelo conteúdo nem pelas políticas / práticas de privacidade dos sites que apontam para o nosso Portal e daqueles para os quais apontamos.
            <br/>

        <b>Exclusão de garantias e de responsabilidade – disponibilidade, continuidade, utilidade e falibilidade</b>
            <br/>
            &emsp;A Petrobras não garante a disponibilidade e continuidade do funcionamento do Portal e dos Serviços. Nem todos os Serviços e Conteúdos em geral se encontram disponíveis para todas as áreas geográficas. Quando isto for razoavelmente possível, a Petrobras advertirá previamente as interrupções no funcionamento do Portal e dos Serviços. A Petrobras tampouco garante a utilidade do Portal e dos Serviços para a realização de alguma atividade em particular, nem sua infalibilidade e, em particular, ainda que não de modo exclusivo, não garante que os usuários possam efetivamente utilizar o Portal e os Serviços, acessar as distintas páginas web que formam o Portal ou aquelas desde as quais se prestam os serviços.
            <br/>
            &emsp;A PETROBRAS SE EXIME DE QUALQUER RESPONSABILIDADE PELAS PERDAS E DANOS DE TODA NATUREZA QUE POSSAM ADVIR DA FALTA DE DISPONIBILIDADE OU DE CONTINUIDADE DO FUNCIONAMENTO DO PORTAL E DOS SERVIÇOS; DA DEFRAUDAÇÃO PELA UTILIDADE QUE OS USUÁRIOS POSSAM TER ATRIBUÍDO AO PORTAL E AOS SERVIÇOS, DA FALIBILIDADE DO PORTAL E DOS SERVIÇOS, E, EM PARTICULAR, AINDA QUE NÃO DE MODO EXCLUSIVO, SE EXIME PELAS FALHAS NO ACESSO ÀS DISTINTAS PÁGINAS WEB DO PORTAL OU ÀQUELAS A QUE SE PRESTAM OS SERVIÇOS.
            <br/>

        <b>Exclusão de garantias e de responsabilidade – contaminação por vírus e assemelhados</b>
            <br/>
            &emsp;A Petrobras não controla nem garante a ausência de vírus nem de outros elementos nos conteúdos que possam produzir alterações em seu sistema de informática (software e hardware) ou nos documentos eletrônicos e arquivos armazenados em seu sistema de informática.
            <br/>
            &emsp;A PETROBRAS SE EXIME DE QUALQUER RESPONSABILIDADE PELAS PERDAS E DANOS, DE TODA NATUREZA, QUE SE POSSAM ATRIBUIR À PRESENÇA DE VÍRUS OU DE OUTROS ELEMENTOS NOS CONTEÚDOS QUE POSSAM PRODUZIR ALTERAÇÕES NO SISTEMA DE INFORMÁTICA, DOCUMENTOS ELETRÔNICOS OU CADASTROS DE USUÁRIOS.
            <br/>

        <b>Direitos autorais e propriedade intelectual</b>
            <br/>
            &emsp;Este Portal contém textos, fotografias, imagens e som, que se encontram protegidos por direitos autorais ou outros direitos de propriedade intelectual. Estes direitos pertencem à Petrobras ou foram outorgados sob licença por seus titulares, de tal forma que a Petrobras pode utilizar este material como parte deste Portal.
            <br/>
            &emsp;Ao acessar a página da Petrobras, o usuário declara que irá respeitar todos os direitos de propriedade intelectual e industrial, os decorrentes da proteção de marcas registradas da mesma, bem como de todos os direitos referentes a terceiros que porventura estejam, ou estiveram, de alguma forma, disponíveis no Portal. O simples acesso ao Portal não confere ao usuário qualquer direito de uso dos nomes, títulos, palavras, frases, marcas, patentes, obras literárias entre outras, que nele estejam, ou estiveram, disponíveis.
            <br/>
            &emsp;Todas as marcas comerciais incluídas neste Portal são de propriedade da Petrobras ou lhe foram outorgadas sob licença ou autorização por seus titulares para sua utilização neste Portal.
            <br/>
            &emsp;A reprodução dos conteúdos descritos anteriormente está proibida, salvo prévia autorização por escrito da Petrobras ou caso se destinem a uso exclusivamente pessoal e sem que em nenhuma circunstância o usuário adquira qualquer direito sobre os mesmos.
            <br/>
            &emsp;É permitido fazer somente o arquivo temporário deste Portal, sendo vedada sua utilização para finalidades comerciais, publicitárias ou qualquer outra que contrarie a realidade para o qual foi concebido, conforme definido neste Termo. Restam igualmente proibidas a reprodução, distribuição e divulgação, total ou parcial, dos textos, figuras, gráficos que compõem o presente Portal, sem prévia e expressa autorização da Petrobras, sendo permitida somente a impressão de cópias para uso e arquivo pessoal publicação científica ou atendimento a requisitos legais do licenciamento ambiental sem que sejam separadas as partes que permitam dar o fiel e real entendimento de seu conteúdo e objetivo.
            <br/>
            &emsp;O usuário assume toda e qualquer responsabilidade, de caráter civil e/ou criminal, pela utilização indevida das informações, textos, gráficos, marcas, obras, enfim, de todo e qualquer direito de propriedade intelectual ou industrial deste Portal.
            <br/>

        <b>Modificações destes Termos e Condições</b>
            <br/>
            &emsp;A Petrobras se reserva o direito de modificar a qualquer momento, de forma unilateral, os presentes Termos e Condições de Uso. Ao navegar por este Portal, você aceita guiar-se pelos Termos e Condições vigentes na data, e, portanto, deve verificá-los previamente cada vez que visitar este Portal.
            <br/>

        <b>Dúvidas</b>
            <br/>
            &emsp;Caso tenha qualquer dúvida em relação ao presente documento, favor entrar em contato pelo <a href="comunica.UO-BS@petrobras.com.br">comunica.UO-BS@petrobras.com.br</a>
            <br/>

        <b>Lei aplicável e jurisdição</b>
            <br/>
            &emsp;Os presentes Termos e Condições de Uso regem-se pela legislação da República Brasileira. Os usuários do Portal do PMPAS-BS se submetem ao Foro Central da Comarca de Santos/SP com exclusão de qualquer outro, por mais privilegiado que seja.

    </div>
);

let privacyPolicy = (
    <div>
            &emsp;Nossa Política de Privacidade On-Line foi elaborada para reafirmar nosso compromisso com a segurança e a privacidade das informações que coletamos dos usuários do site <a href="(http://www. www.simmao.pmpabs.eco.br/">www.simmao.pmpabs.eco.br</a>, serviço oferecido por Petróleo Brasileiro S.A. por meio do Instituto de Pesquisa da Marinha do Brasil (IPqM), daqui por diante designada Petrobras.
            <br/>
            &emsp;Esta política de privacidade abrange o tratamento dado pela Petrobras às informações capazes de identificar os usuários, coletadas quando estes estão no site do Projeto de Monitoramento da Paisagem Acústica Submarina da Bacia de Santos (PMPAS-BS) da Petrobras na internet ou que venham a compor bases de dados eletrônicos da empresa.
            <br/>
            &emsp;Lembramos que esta Política de Privacidade está sujeita a constantes melhorias e aprimoramentos. Assim, recomendamos sua leitura periódica.
            <br/>
            &emsp;Entretanto, caso, em algum momento, promova mudança substancial na maneira como usa as informações pessoais coletadas, a Petrobras informará as novas condições de privacidade: a) ao público em geral, mediante anúncio em nossa página principal; b) aos usuários cadastrados, mediante comunicação eletrônica. 
            <br/>
            &emsp;Para fins desta Política, devem ser observadas as seguintes considerações:
            <br/>
            &emsp;1. A Petrobras coleta informações pessoais capazes de identificar os usuários, como textos e imagens, quando estes se cadastram no website do PMPAS-BS. Os usuários serão informados sobre os dados que estão sendo coletados, ficando a seu critério fornecê-los ou não.
            <br/>
            &emsp;2. A Petrobras faz uso de cookies, recebendo, automaticamente, informações sobre a navegação do usuário durante uma visita. O recurso tem a finalidade de melhorar a experiência do usuário, ao permitir a adequação e personalização de páginas ao seu perfil. Todavia, o próprio usuário pode configurar seu navegador para não ser rastreado.
            <br/>
        <b>Do Usuário</b>
        <br/>
            &emsp;3. A entrada de dados pessoais (e-mail) do usuário no site do PMPAS-BS constitui requisito para navegar pelo site, sendo, portanto, obrigatório. O usuário que decide fornecer seus dados pessoais para navegar pelo site declara conhecer e aceitar os termos desta Política.
            <br/>
            &emsp;4. Envidaremos nossos melhores esforços para manter a integridade e a confidencialidade das informações que nos forem fornecidas. Embora a Petrobras utilize tecnologias para segurança de aplicações via web, o usuário reconhece que não se pode ter expectativa de segurança total na Internet com relação a invasão de websites, roubo, adulteração ou eliminação de dados, e outros atos ilícitos.
            <br/>
            &emsp;5. Todos os dados pessoais coletados serão incorporados ao banco de dados da Petrobras.
            <br/>
            &emsp;6. A Petrobras se compromete a utilizar sistemas de proteção disponibilizados pela tecnologia de internet, razão pela qual não é responsável pelos danos decorrentes do seu acesso e/ou utilização por terceiros estranhos, seja dolosa ou culposamente.
            <br/>
            &emsp;7. A Petrobras utiliza as informações coletadas para os seguintes propósitos: (i) identificar os usuários que acessam o site do PMPAS-BS; (ii) elaborar estatísticas gerais.
            <br/>
            &emsp;8. O acesso às informações coletadas é restrito aos funcionários e pessoas autorizadas para este fim. Empregados e/ou pessoas autorizadas que se utilizarem indevidamente dessas informações, ferindo nossa Política de Privacidade, estarão sujeitos às medidas disciplinares e legais cabíveis.
            <br/>
            &emsp;9. A menos que tenhamos determinação legal ou judicial, as informações dos usuários não serão transferidas a terceiros ou usadas para finalidades diferentes daquelas para as quais foram coletadas pela Petrobras, salvo na hipótese de mudança nos propósitos mencionados no item 7, quando haverá prévia comunicação da Petrobras.
            <br/>
            &emsp;10. O usuário garante a veracidade e a exatidão dos dados pessoais que fornecer neste site, assumindo a correspondente responsabilidade caso não sejam exatos. A Petrobras não assume qualquer responsabilidade em caso de inexatidão dos dados pessoais introduzidos pelo usuário neste site.
            <br/>
            &emsp;11. O usuário que introduzir seus dados pessoais poderá, a qualquer momento, solicitar que lhe seja informado o conteúdo dos mesmos, bem como as retificações ou supressões que julgue convenientes, inclusive totais.
            <br/>
            &emsp;12. A Petrobras e as empresas do sistema Petrobras têm suas próprias políticas de privacidade. Embora estejam alinhadas, é recomendável que se tome conhecimento das políticas de seus respectivos sites.
            <br/>
            &emsp;Se você tem perguntas ou sugestões, por favor, envie um e-mail para <a href="comunica.UO-BS@petrobras.com.br">comunica.UO-BS@petrobras.com.br</a>
        <br/>
    
    </div>
);

export {termsOfUse, privacyPolicy};