import CryptoJS from 'crypto-js'
import API from './api'

function encryptData(data){
    var ciphertext = CryptoJS.AES.encrypt(data.toString(), 'secret key 123');
    return ciphertext;
}

function decryptData(value){ 
    if(value !== undefined){
        try{
            var bytes  = CryptoJS.AES.decrypt(value.toString(), 'secret key 123');
            var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedData;
        }catch(error){
            console.log("Decryption error: ",error);
            return false;
        }
    }
}

function validate(token){
        if(!token) return false;

        const api = new API();
        var exp_date =  new Date;
        var actual_date =  new Date;
        var validation = api.get_user_data(token);

        exp_date.setTime(decryptData(localStorage.exp) || false);
        if(exp_date.getTime() < actual_date.getTime() || validation.status != 200){
            localStorage.removeItem('exp')
            localStorage.removeItem('token')
            return false;
        }else return true
}

export {encryptData, decryptData, validate}