import React from 'react';
import chroma from 'chroma-js';
import Reflux from 'reflux';

import Select from 'react-select';

import _ from 'lodash';
import API from '../../api';

const api = new API();


// GROUP OPTIONS

const metricsOptions = [
    { group: "metrics", value: "mean", label: "Média", color: "#15a3db", groupLabel: 'Métricas'},
    { group: "metrics", value: "5p", label: "5 Percentil", color: "#15a3db", groupLabel: 'Métricas'},
    { group: "metrics", value: "50p", label: "50 Percentil", color: "#15a3db", groupLabel: 'Métricas'},
    { group: "metrics", value: "95p", label: "95 Percentil", color: "#15a3db", groupLabel: 'Métricas' },

];

const periodOptions = [
    { group: "period", value: "001", label: "Janeiro", color: "#e5063a", groupLabel: 'Período'},
    { group: "period", value: "002", label: "Fevereiro", color: "#e5063a", groupLabel: 'Período' },
    { group: "period", value: "003", label: "Março", color: "#e5063a", groupLabel: 'Período' },
    { group: "period", value: "004", label: "Abril", color: "#e5063a", groupLabel: 'Período' },
    { group: "period", value: "005", label: "Maio", color: "#e5063a", groupLabel: 'Período' },
    { group: "period", value: "006", label: "Junho", color: "#e5063a", groupLabel: 'Período' },
    { group: "period", value: "007", label: "Julho", color: "#e5063a", groupLabel: 'Período' },
    { group: "period", value: "008", label: "Agosto", color: "#e5063a", groupLabel: 'Período' },
    { group: "period", value: "009", label: "Setembro", color: "#e5063a", groupLabel: 'Período' },
    { group: "period", value: "010", label: "Outubro", color: "#e5063a", groupLabel: 'Período' },
    { group: "period", value: "011", label: "Novembro", color: "#e5063a", groupLabel: 'Período' },
    { group: "period", value: "012", label: "Dezembro", color: "#e5063a", groupLabel: 'Período' },
    { group: "period", value: "VER", label: "Verão", color: "#e5063a", groupLabel: 'Período' },
    { group: "period", value: "PRI", label: "Primavera", color: "#e5063a", groupLabel: 'Período' },
    { group: "period", value: "OUT", label: "Outono", color: "#e5063a", groupLabel: 'Período' },
    { group: "period", value: "INV", label: "Inverno", color: "#e5063a", groupLabel: 'Período' },
]

let templates = {
    frequency:  {color: "#FF8B00", groupLabel:'Frequência'},
    year: {color: "#FFC400", groupLabel:'Ano'},
    depth: {color: "#36B37E", groupLabel:'Profundidade'},
}

//CUSTOM STYLES

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

const colourStyles = {
    control: styles => ({
        ...styles,
        backgroundColor: 'white',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        
        return {
            ...styles,
            backgroundColor: data.title ? '#fefefe' : color.alpha(0.1).css(),
            color: data.title ? '#253858' : isDisabled ? '#ccc' : '#555',
            cursor: data.title ? 'default' : isDisabled ? 'not-allowed' : 'pointer',
        };
    },
    multiValue: (styles, { data }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {
            backgroundColor: data.color,
            color: 'white',
        },
    }),
    menu: base => ({
        ...base,
        height: 'calc(100vh - 283px)'
    }),
    menuList: base => ({
        ...base,
        height: '100%',
        maxHeight: '100%',
    }),
    container: base => ({
        ...base,
        width: '99%',
        margin: 'auto',
        maxWidth: '345'

    })
};

// MULTI-SELECT COMPONENT

export default class SelectComponent extends Reflux.Component {
    constructor(props) {
        super(props);
        this.options = {
            depth: [],
            metrics: metricsOptions,
            frequency: [],
            year: [],
            period: periodOptions
        }
        this.groupedOptions = [
            {label: 'Métricas'},
            {label: 'Profundidade'},
            {label: 'Frequência'},
            {label: 'Ano'},
            {label: 'Período'},
        ];
        this.apiResponse = null;
    }

    onChange(data) {
        let metricsFlag = false

        let selections = {}
        if (data.length > 4)
            this.props.buttonCallback(true);
        else
            this.props.buttonCallback(false);

        if (data.length > 0){
            data.map(selection => {
                if(selection.group == 'metrics')
                    metricsFlag = true;
            });
        }
        
        metricsOptions.map(key => {
            key.isDisabled = metricsFlag;
        });

        data.map(option => {
            selections[option.group] = option.value;
        });

        let selectionFilter = Object.keys(selections).reduce((object, key) => {
            if (key !== 'metrics') 
                object[key] = selections[key]
            return object
        }, {})

        let reject = _.filter(this.apiResponse, selectionFilter)
        for(var key in this.options){
            this.options[key].map(object => {
                let filter = {}; 
                if(object.group != 'metrics'){
                    filter[object.group] = object.value; 
                    let filterResult =  _.filter(reject, filter);
                    if(filterResult.length == 0)
                        object.isDisabled = true;
                    else object.isDisabled = false;
                }
            });
        }
        this.props.selectionCallback(selections);
    
    }

    componentWillMount(){
            metricsOptions.map(key => {
                key.isDisabled = false;
            });
            periodOptions.map(key => {
                key.isDisabled = false;
            });
            this.apiResponse = api.get_noisemap().responseJSON;
            this.apiResponse.map(response => {
            for(var key in response){
                let selection = {}
                if(templates[key] !== undefined){
                    selection['group'] = key;
                    selection['value'] = response[key];
                    switch(key){
                        case 'frequency': 
                        selection['label'] = response[key] + 'hz';
                        break;
                        case 'depth': 
                        selection['label'] = response[key] + 'm';
                        break;
                        default:
                        selection['label'] = response[key];
                    }
                    selection['color'] = templates[key]['color'];
                    selection['groupLabel'] = templates[key]['groupLabel'];
                    if(_.filter(this.options[key], {'value': selection['value']}).length == 0){
                        this.options[key].push(selection);
                        this.options[key] = _.sortBy(this.options[key], ['value']);
                    } 
                }
            }

            this.groupedOptions.map(group => {
                for(var key in this.options){
                    if(this.options[key][0].groupLabel == group.label)
                        group['options'] = this.options[key];
                }
            })
            
        });
    }


    render() {
        if(this.groupedOptions[0].options !== undefined)
            return <Select
                closeMenuOnSelect={false}
                isMulti
                menuIsOpen
                options={this.groupedOptions}
                styles={colourStyles}
                formatGroupLabel={formatGroupLabel}
                onChange={this.onChange.bind(this)}
                placeholder={'Selecione as configurações'}
                components={
                    {
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                    }
                }
            />
        else return( null );
    }
}
