import React from 'react';
import Reflux from 'reflux';
import { Redirect } from 'react-router-dom'

import { Panel,
    Col,
    Row,
    FormGroup,
    FormControl,
    InputGroup} from 'react-bootstrap';
import {Icon, Button} from 'antd';

import Header from '../twist_header/Header';
import styles from './Reset.css';
import {AuthStore} from '../../store.js';
import {AuthActions} from '../../actions.js';
import {decryptData, validate} from '../../crypto.js'
import API from '../../api'
import { ErrorMessage } from '../login/Login';


class ResetPanel extends Reflux.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div>
                <Header />
                <Row>
                    <Col sm={6} smOffset={6} md={4} mdOffset={4} className={styles.loginForm}>
                        <Panel bsStyle="default" className={styles.panel}>
                            <Panel.Heading  className={styles.headerForm}>
                                <Panel.Title>
                                    <div className={styles.headerIcon}><i className={"fas fa-key"}></i></div>
                                    <h3>{'Recuperação de Senha'}</h3>
                                    <h3>{'Password Recovery'}</h3>
                                </Panel.Title>
                            </Panel.Heading>
                            <Panel.Body style={{textAlign: 'center'}}>
                                {this.props.children}
                            </Panel.Body>
                        </Panel>
                        <div className={styles.link}>
                            <a onClick={this.props.handleLogin}> {'Voltar para o Login'}</a>
                            <br></br>
                            <a onClick={this.props.handleLogin}> {'Return to Login'}</a>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}


export default class Reset extends Reflux.Component {

    constructor(props) {
        super(props);
        this.uid = props.match.params.uid || null;
        this.token = props.match.params.token || null;
        this.store = AuthStore;
        this.handleChange = this.handleChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLoginRedirect = this.handleLoginRedirect.bind(this);
        this.handleSendEmail = this.handleSendEmail.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.api = new API();
        this.state = {
            email: '',
            new_password1: '',
            new_password2: '',
            loading: false
        };
    }
    validateForm() {
        return this.state.new_password1.length > 0 && this.state.new_password2.length > 0;
    }

    validateEmail() {
        return this.state.email.length > 0 ;
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleReset(event) {
        event.preventDefault();
        this.setState({loading: true});
        try {
            AuthActions.resetPassword(
                this.uid,
                this.token,
                this.state.new_password1,
                this.state.new_password2
            );
        } catch (e) {
            console.log('Reset error')
        }
    }

    handleSendEmail(event) {
        event.preventDefault();
        this.setState({loading: true});
        try {
            AuthActions.sendEmail(
                this.state.email
                );
        } catch (e) {
            console.log('Send email error');
        }
    }

    handleLogin() {
        AuthActions.resetStates();
        this.props.history.push('/');
    }

    handleLoginRedirect() {
        window.setTimeout(function(){
            AuthActions.resetStates();
            this.props.history.push('/');
        }.bind(this), 3000);
    }

    render(){
        if (this.state.loggedin === true || validate(decryptData(localStorage.token))){
            return <Redirect to='/panel' />
        }

        let bodyContent = (
            <div>
                <ErrorMessage/>
                <form onSubmit={this.handleSendEmail}>
                    <FormGroup controlId="email" bsSize="large">
                        <p>{'Insira abaixo o e-mail da conta a ser recuperada.'}</p>
                        <InputGroup>
                            <InputGroup.Addon style={{backgroundColor: 'white'}}>
                                <Icon type="mail" />
                            </InputGroup.Addon>
                            <FormControl
                                autoFocus
                                type="email"
                                placeholder={"E-mail"}
                                value={this.state.email}
                                onChange={this.handleChange}
                            />
                        </InputGroup>
                    </FormGroup>
                    <Button type="primary" className={styles.submitButton + ' ' + (!this.validateEmail() ? styles.disabled : '') } loading={this.state.loading} htmlType={'submit'}> {'Confirmar | Confirm'} </Button>
                </form>
            </div>
        );

        if(this.state.send_success){
            bodyContent = (
                <div>
                    <p>{'E-mail enviado com sucesso! Por favor siga os passos indicados.'}</p>
                    <p>{'Caso não tenha recebido clique aqui para '}<a onClick={() => {AuthActions.resetStates()}}> {'re-enviar'}</a></p>
                </div>
            );
        }

        if(this.state.reset_success){
            this.handleLoginRedirect();
            bodyContent =  (
                <div>
                    <p>{'Sua senha foi alterada com sucesso.'}</p>
                    <p>{'Você será redirecionado para a página de login dentro de alguns instantes.'}</p>
                </div>
            );
        }


        if(this.uid != null && this.token != null){
            bodyContent = (
                <div>
                    <ErrorMessage/>
                    <p>{"Insira nos campos abaixo sua nova senha."}</p>
                    <form onSubmit={this.handleReset}>
                        <FormGroup controlId="new_password1" bsSize="large">
                            <InputGroup>
                                <InputGroup.Addon style={{backgroundColor: 'white'}}>
                                    <Icon type="lock" />
                                </InputGroup.Addon>
                                <FormControl
                                    placeholder={"Senha | Password"}
                                    value={this.state.new_password1}
                                    onChange={this.handleChange}
                                    type="password"
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup controlId="new_password2" bsSize="large">
                            <InputGroup>
                                <InputGroup.Addon style={{backgroundColor: 'white'}}>
                                    <Icon type="lock" />
                                </InputGroup.Addon>
                                <FormControl
                                    placeholder={"Confirmar Senha | Confirm Password"}
                                    value={this.state.new_password2}
                                    onChange={this.handleChange}
                                    type="password"
                                />
                            </InputGroup>
                        </FormGroup>
                        <Button type="primary" className={styles.submitButton + ' ' + (!this.validateForm() ? styles.disabled : '') } loading={this.state.loading} htmlType={'submit'}> {'Confirmar | Confirm'} </Button>
                    </form>
                </div>
            );
        }

        return (
            <ResetPanel handleLogin={this.handleLogin}>
                {bodyContent}
            </ResetPanel>
        );

    }
}
