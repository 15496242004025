const Reflux = require('reflux');

const MapActions = Reflux.createActions([
    'addDepthFilter',
    'addScaleFilter',
    'clearDefaults',
    'addFilterEquipment',
    'applyFilters',
    'downloadPathData',
    'filterDate',
    'filterLimit',
    'changeLimit',
    'getSpectrumData',
    'hideModal',
    'loadRaster',
    'loadRasterBand',
    'loadInitialData',
    'removeFilterEquipment',
    'retrieveEquipment',
    'selectBand',
    'selectMapOption',
    'selectRasterAPI',
    'showModal',
    'storeMap',
]);


const EquipmentListActions = Reflux.createActions([
    'loadEquipments',
]);

const EquipmentPlotActions = Reflux.createActions([
    'setEquipment',
    'loadData',
    'plotDetail',
    'closeModal',
    'addDepthFilter',
    'setSPL',
    'setSel24hModalData',
    'closeSel24hModal'
]);


const AuthActions = Reflux.createActions({
    login: {children: ['completed', 'failed']},
    registration: {children: ['completed']},
    resetPassword: {children: ['completed']},
    sendEmail: {children: ['completed']},
    logout: {},
    resetStates: {}
});

const MenuActions = Reflux.createActions([
    'showRequestFormModal',
    'hideRequestFormModal',
    'handleRequestTextareaChange',
    'requestData',
]);

const PlotMapActions = Reflux.createActions([
    'updateDateRange',
    'updateDepthRange',
    'updateFrequencyRange',
    'resetDateRange',
    'resetDepthRange',
    'resetFrequencyRange',
    'getData',
    'clearData'
]);

const WeatherActions = Reflux.createActions([
    {'getWeatherData': {children: ['completed']}}
]);

export {
    PlotMapActions,
    MapActions,
    AuthActions,
    MenuActions,
    EquipmentListActions,
    EquipmentPlotActions,
    WeatherActions
};
