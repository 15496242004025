import React from 'react';
import Reflux from 'reflux';
import Plot from 'react-plotly.js';
import API from '../../api.js';
import {
    Modal, Button, Tabs, Tab
} from 'react-bootstrap';
import {MapStore, EquipmentPlotStore} from '../../store';
import {MapActions,EquipmentPlotActions} from '../../actions';
import {PlotPathModal} from './PlotPathModal'
import _ from 'lodash';
import fileDownload from 'js-file-download';

//antd
import '!style-loader!css-loader!rc-slider/assets/index.css';
import {Table, message} from 'antd';


const terms =  'Estes dados ostensivos podem ser apenas utilizados para finalidades científicas. É proibida a utilização para quaisquer aplicações comerciais ou de cunho empresarial. O uso inadequado sujeitará o usuário e sua empresa ou instituição às ações jurídicas cabíveis.';

class SpectralGridChart extends Reflux.Component {
    constructor(props, context){
        super(props, context);
        const initial_data = this.renderData(props.data[props.serie]);
        this.state = {
            name: props.title,
            serie: props.serie,
            data: initial_data,
            initial_data: initial_data,
            depth_range: null,
            date_range: null,
            frequency_range: null,
            layout:{
                updatemenus: this.get_updatemenus(initial_data),
                // Frequency Plot
                xaxis: {type: 'log', domain:[0, 1.], title:'Frequência (Hz)', fixedrange:true},
                yaxis: {domain:[0.7, 1.], title:'Intensidade (dB ref. 1µPa²)', fixedrange:true},
                // Amount of samples by depth plot
                xaxis2: {anchor: 'y2', domain:[0, 0.45], fixedrange:true, title:'Amostras'},
                yaxis2: {domain:[0.35, 0.6], fixedrange: true, autorange:'reversed', title:'Profundidade (m)'},
                // Amount of samples by intensity
                xaxis3: {anchor: 'y3', domain:[0.55, 1.], fixedrange: true},
                yaxis3: {domain:[0.35, 0.6], side:'right', fixedrange:true, title:'Amostras'},
                // Depth by intensity plot
                xaxis4: {anchor: 'y4', domain:[0, 0.45], fixedrange:true, title:'Intensidade (dB ref. 1µPa²)'},
                yaxis4: {domain:[0, 0.25], autorange:'reversed', title:'Profundidade (m)'},
                // Intensity value by date plot
                xaxis5: {anchor: 'y5', domain:[0.55, 1.]},
                yaxis5: {domain:[0., 0.25], side:'right', fixedrange:true, title:'Intensidade (dB ref. 1µPa²)'},
                width: 800,
                height:680,
                hovermode:false,
                showlegend:false,
            },
            config: {
                displayModeBar: false,
                displaylogo: false
            }
        };
    }

    componentDidMount(){
        let _layout = this.state.layout;
        _layout.xaxis3['range'] = this.props.range;
        _layout.xaxis3['autorange'] = this.props.range ? false : true;
        this.setState({
            layout: _layout
        });
    }

    componentDidUpdate(prevProps){
        if(this.props.range != prevProps.range){
            let _layout = this.state.layout;
            _layout.xaxis3['range'] = this.props.range;
            _layout.xaxis3['autorange'] = this.props.range ? false : true;
            this.setState({
                layout: _layout
            });
        }
    }

    get_updatemenus(initial_data){
        return  [{
            type: 'buttons',
            showactive: true,
            pad: {r: -10, t: -80},
            x: 0.95,
            xanchor: 'left',
            yanchor: 'top',
            direction: 'left',
            buttons: [
                {
                    label: 'Redefinir limites',
                    method: 'skip'
                }
            ]
        }];
    }

    filter_data(data, parameter, range){
        const new_data = this.renderData(_.filter(
            data,
            e => {
                if(parameter == 'datetime'){
                    let date = new Date(e[parameter]);
                    return date >= range[0] && date <= range[1];
                }else
                    return e[parameter] >= range[0] && e[parameter] <= range[1];
            }));
        return new_data;
    }

    renderData( data , opt ) {
        if(data == undefined){
            message.config({
                maxCount: 1
              });
            message.error('Nenhum dado disponível');
            MapActions.hideModal();
        }
        opt = _.extend({}, { type: 'scatter', mode: 'lines', }, opt||{});
        let new_data = [];
        _.each(data, function(plt){
            new_data.push(_.extend({}, plt, opt));
        });
        const mean_intensity = _.map(_.map(data, 'y'), _.mean);
        const hist_depth = {
                y: _.map(data, 'depth'),
                xaxis: 'x2',
                reverse:true,
                autorange: true,
                yaxis: 'y2',
                type: 'histogram',
                title: 'Profundidade'
            };
        const hist_date = {
                x: _.map(data, 'datetime'),
                xaxis: 'x3',
                yaxis: 'y3',
                type: 'histogram',
                title: 'Data Aquisição'
            };
        const scatter_depth = {
                y: _.map(data, 'depth'),
                x: mean_intensity,
                xaxis: 'x4',
                reverse:true,
                yaxis: 'y4',
                autorange: true,
                mode: 'markers',
                type: 'scatter',
                title: 'Profundidade'
            };
        const scatter_date = {
                x: _.map(data, 'datetime'),
                y: mean_intensity,
                xaxis: 'x5',
                yaxis: 'y5',
                autorange: true,
                mode: 'markers',
                type: 'scatter',
                name: 'Data Aquisição'
            };
        new_data.push(hist_depth);
        new_data.push(scatter_depth);
        new_data.push(hist_date);
        new_data.push(scatter_date);
        return new_data;
    }


    updateDateRange(eventdata){
        let range = _.sortBy(_.values(eventdata));
        let offset = (new Date(range[0])).getTimezoneOffset() * 60000;
        range  = range.map(date => new Date( new Date(date) - offset));
        const data = this.filter_data(this.state.data, 'datetime', range);
        this.setState({
            data: data,
            date_range: range,
        });         
        range = _.sortBy(_.values(eventdata));
        this.props.updateRangeCallback(range);
    }

    updateDepthRange(eventdata){
        const range = _.sortBy(_.values(eventdata));
        const data = this.filter_data(this.state.data, 'depth', range);
        this.setState({
            data: data,
            depth_range: range
        });
    }

    resetRange(eventdata){
        let _layout = this.state.layout;
        _layout.xaxis3['range'] = null ;
        _layout.xaxis['autorange'] = true;
        _layout.xaxis3['autorange'] = true;
        _layout.yaxis4['autorange'] = true;
        _layout.xaxis5['autorange'] = true;
        this.setState({
            data: this.state.initial_data,
            date_range: null,
            depth_range: null,
            layout: _layout
        });
    }


    onSelected(eventdata){
        const key = _.keys(eventdata)[0];
        const selector = key.split('.')[0];
        const command = (key.split('.')[1] == 'autorange'? 'reset': 'range');
        const selectorMap = {
            xaxis: {
                'range': null,
                'reset': null,
            },
            yaxis2: {
                'range': this.updateDepthRange.bind(this),
                'reset': this.resetRange.bind(this),
            },
            yaxis4: {
                'range': this.updateDepthRange.bind(this),
                'reset': this.resetRange.bind(this),
            },
            xaxis3: {
                'range': this.updateDateRange.bind(this),
                'reset': this.resetRange.bind(this),
            },
            xaxis5: {
                'range': this.updateDateRange.bind(this),
                'reset': this.resetRange.bind(this),
            }
        };
        if(selectorMap[selector][command] !== null)
            selectorMap[selector][command](eventdata);
    }


    onButtonClicked(eventdata){
        let _layout = this.state.layout;
        _layout.xaxis3['range'] = null ;
        _layout.xaxis['autorange'] = true;
        _layout.xaxis3['autorange'] = true;
        _layout.yaxis4['autorange'] = true;
        _layout.xaxis5['autorange'] = true;
        this.setState({
            data: this.state.initial_data,
            date_range: null,
            depth_range: null,
            layout: _layout
        });

    }

    render(){
        if(_.isUndefined(this.state.data)){
            return null;
        }
        return  (
            <div>
                <p id='linhasEspec'>Linhas Analisadas no Espectro : {this.state.data.length -4}</p>
                <Plot data={this.state.data}
                      config={this.state.config}
                      layout={this.state.layout}
                      style={{width: "100%", height: "100%"}}
                      onRelayout={this.onSelected.bind(this)}
                      onButtonClicked={this.onButtonClicked.bind(this)}
                />
            </div>
        );
    }
}


class PlotSpectrumGridTab extends Reflux.Component {
    constructor(props, context){
        super(props, context);
        this.state = {range: null};
        this.series = ['esp5p', 'esp50p', 'esp95p', 'espm'];
        this.data = this.getData(props.lat,
                                 props.lng,
                                 props.filters);
    }


    getData(lat, lng, filters){
        const api = new API();
        let data = null;
        api.get_spectrumgrid(lat, lng, filters).done(function(d){
            data = d || [];
        });
        return data;
    }

    updateRangeCallback(range){
        this.setState({
            range: range
        })
    }

    render(){
        if (!this.data){
            message.config({
                maxCount: 1
              });
            message.error('Nenhum dado disponível')
            MapActions.hideModal();
            return null;
        }

        const TABSeries = this.series.map((serie, idx) =>
            <Tab eventKey={idx} key={idx} title={serie}>
                <SpectralGridChart
                    key={idx}
                    data={this.data}
                    range={this.state.range}
                    updateRangeCallback={this.updateRangeCallback.bind(this)}
                    title={serie}
                    serie={serie}
                    style={{margin: "auto"}}
                />
            </Tab>
        );
        return <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                    {TABSeries}
               </Tabs>;
    }
}

class Sel24hModalTable extends Reflux.Component {

    constructor(props){
        super(props);
        this.columns = [{
            title: 'Modelo',
            dataIndex: 'type',
            key: 'type',
          },{
            title: 'N.º de série',
            dataIndex: 'name',
            key: 'name',
          },{
            title: 'Campanha',
            dataIndex: 'campaign',
            key: 'campaign',
            align: 'center'
          },{
            title: 'Data',
            dataIndex: 'datetime',
            key: 'datetime',
            align: 'center'
        },{
            title: 'Latitude',
            dataIndex: 'lat',
            key: 'lat',
            align: 'center'
        },{
            title: 'Longitude',
            dataIndex: 'long',
            key: 'long',
            align: 'center'
          },{
            title: 'SEL (dB ref. 1μPa²⋅s)',
            dataIndex: 'value',
            key: 'value',
            align: 'center'
        },{
            title: 'Nº de pontos',
            dataIndex: 'n_points',
            key: 'n_points',
            align: 'center'
        }];
        this.data = this.getData(
            props.lat,
            props.lng,
            props.filters
        );
    }


    getData(lat, lng, filters){
        const api = new API();
        let data = null;
        api.get_soundexposure24hgrid(lat, lng, filters).done(function(d){
        data = d || [];
        });
        return data;
    }

    render() {
        let tableData = [];
        // Plot
        try{
            this.data.data.map(obj => {
                tableData.push({
                    key: _.uniqueId('row-'),
                    datetime: obj.datetime,
                    lat: obj.lat.toFixed(4),
                    long: obj.long.toFixed(4),
                    name: obj.name,
                    campaign: obj.campaign,
                    type: obj.type,
                    value: obj.value.toFixed(1),
                    n_points: obj.used_points+'/'+obj.max_points
                  });
            })
        }
        catch(err){
            message.config({
                maxCount: 1
              });
            message.error('Nenhum dado disponível')
            MapActions.hideModal();
            return null
        }

        return <Table dataSource={tableData} columns={this.columns} pagination={false} />
    }
}

class Sel24hModal extends Reflux.Component {

    constructor(props){
        super(props);
        this.state = {showTerms: false, loading: false};
        this.store = MapStore;
        this.storeKeys = ['showModal', 'lat', 'lng', 'filters'];
        this.ids = {
            'table': _.uniqueId('table-')
        }
    }

    close(){
        MapActions.hideModal();
    }

    showTerms(terms){
        this.setState({showTerms: terms, showModal: !terms});        
    }
    
    downloadData(){
        const api = new API();
        let data = null;
        api.get_soundexposure24hgrid(this.state.lat, this.state.lng, this.state.filters).done(function(d){
            data = d || [];
        });
        fileDownload(JSON.stringify(data), 'data.json');
        this.showTerms(false);
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    show={this.state.showModal}
                    bsSize="large"
                    aria-labelledby="contained-modal-title-vcenter"
                    key={_.uniqueId('Sel24hModal-')}
                    dialogClassName="sel24h-modal"
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-lg">{'SEL 24h'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{width: 950}}>
                        <Sel24hModalTable key={this.ids.table} lat={this.state.lat} lng={this.state.lng} filters={this.state.filters} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.showTerms.bind(this, true)}>{'Baixar Dados'}</Button>
                        <Button onClick={this.close.bind(this)}>{'Fechar'}</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showTerms}>
                    <Modal.Body>
                        {terms}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.downloadData.bind(this)}>{'Aceitar'}</Button>
                        <Button onClick={this.showTerms.bind(this, false)}>{'Fechar'}</Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}

class PlotSel24hModal extends Reflux.Component {

    constructor(props){
        super(props);
        this.state = {showTerms: false, loading: false};
        this.stores = [EquipmentPlotStore];
        this.storeKeys = ['sel24h_plot_data', 'showSel24hModal'];
        this.columns = [{
            title: 'Modelo',
            dataIndex: 'type',
            key: 'type',
          },{
            title: 'N.º de série',
            dataIndex: 'serial',
            key: 'serial',
          },{
            title: 'Campanha',
            dataIndex: 'campaign',
            key: 'campaign',
            align: 'center'
          },{
            title: 'Profundidade',
            dataIndex: 'depth',
            key: 'depth',
          }, {
            title: 'Data',
            dataIndex: 'date',
            key: 'date',
          }, {
            title: 'SEL (dB ref. 1μPa²⋅s)',
            dataIndex: 'value',
            key: 'value',
          }, {
            title: 'N.º de pontos',
            dataIndex: 'npoints',
            key: 'npoints',
            align: 'center'
          }];
    }
    close(){
        EquipmentPlotActions.closeSel24hModal();
        EquipmentPlotActions.setSel24hModalData(null);
    }

    showTerms(terms){
        this.setState({showTerms: terms, showSel24hModal: !terms});        
    }
    
    downloadData(){
        fileDownload(JSON.stringify(this.state.sel24h_plot_data), 'data.json');
        this.showTerms(false);
    }

    render() {
        let data = null;
        let tableData = [];
        if (!this.state.sel24h_plot_data)
            return null;

        // Plot
        data = this.state.sel24h_plot_data;
        data.map(obj => {
            let fixedValue = 0;
            if (obj.value) {
                fixedValue = Number(obj.value).toFixed(1)
            } else {
                fixedValue = obj.value;
            }

            tableData.push({
                key: _.uniqueId('row-'),
                date: obj.date,
                npoints: obj.npoints+'/'+obj.max_points,
                depth: obj.depth,
                value: fixedValue,
                campaign: obj.campaign,
                serial: obj.serial,
                type: obj.type
              });
        })
        

        return (
            <React.Fragment>
                <Modal
                    show={this.state.showSel24hModal}
                    bsSize="large"
                    aria-labelledby="contained-modal-title-vcenter"
                    key={_.uniqueId('Sel24hModal-')}
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-lg">{'SEL 24h'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table dataSource={tableData} columns={this.columns} pagination={false} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.showTerms.bind(this, true)}>{'Baixar Dados'}</Button>
                        <Button onClick={this.close.bind(this)}>{'Fechar'}</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showTerms}>
                    <Modal.Body>
                        {terms}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.downloadData.bind(this)}>{'Aceitar'}</Button>
                        <Button onClick={this.showTerms.bind(this, false)}>{'Fechar'}</Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}

class PlotSpectrumGridComponent extends Reflux.Component {
    constructor(props, context){
        super(props, context);
        this.state = {showTerms: false};
        this.store = MapStore;
        this.storeKeys = ['showModal', 'lat', 'lng', 'filters'];
    }
    close(){
        MapActions.hideModal();
    }

    setData(data){
        this.setState({'plot_data': data});
    }

    downloadData(){
        const api = new API();
        let data = null;
        api.get_spectrumgrid(this.state.lat, this.state.lng, this.state.filters).done(function(d){
            data = d || [];
        });
        fileDownload(JSON.stringify(data), 'data.json');
        this.showTerms(false);
    }

    showTerms(terms){
        this.setState({showTerms: terms, showModal: !terms});        
    }


    render() {
        return (
            <React.Fragment>
                <Modal
                    show={this.state.showModal}
                    key={_.uniqueId('plot-modal-plotspectrumcomponent-')}
                    bsSize="large"
                    aria-labelledby="contained-modal-title-lg"
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-lg">Espectros</Modal.Title>
                    </Modal.Header>
                    <Modal.Body> <PlotSpectrumGridTab lat={this.state.lat} lng={this.state.lng} filters={this.state.filters}/> </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.showTerms.bind(this, true)}>{'Baixar Dados'}</Button>
                        <Button onClick={this.close.bind(this)}>{'Fechar'}</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showTerms}>
                    <Modal.Body>
                        {terms}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.downloadData.bind(this)}>{'Aceitar'}</Button>
                        <Button onClick={this.showTerms.bind(this, false)}>{'Fechar'}</Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}


export default class PlotModal extends Reflux.Component {
    constructor(props, context){
        super(props, context);
        this.state = {};
        this.ids = {
            'sel': _.uniqueId('sel24h-modal-'),
            'grid': _.uniqueId('plot-modal-'),

        }
        this.store = MapStore;
    }

    render(){
        if (this.state.mapOption == 'path'){
            return  <PlotPathModal key={_.uniqueId('plotpath-modal-')}/>;
        }
        if (this.state.mapOption == 'sel24h'){
            return  <Sel24hModal key={this.ids.sel}/>;
        }
        
        return  <PlotSpectrumGridComponent key={this.ids.grid}/>;
    }
}

export {PlotModal, PlotSel24hModal}
