import Reflux from 'reflux';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import {MapActions,
        PlotMapActions,
        EquipmentListActions,
        EquipmentPlotActions,
        AuthActions,
        MenuActions,
        WeatherActions
} from './actions';

import {encryptData} from './crypto'
import API from './api.js';

var Config = require('Config');


function setCookie(days) {
    var d = new Date();
    d.setTime(d.getTime() + (days*24*60*60*1000));
    localStorage.setItem('exp', encryptData(d.getTime()));

}

class MenuStore extends Reflux.Store{
    constructor(){
        super();
        this.listenables = MenuActions;
        this.state = {
            'showRequestFormModal': false,
            'requestTextareaValue': '',
            'reponse': null
        };
    }
    onShowRequestFormModal(){
        this.setState({
            'showRequestFormModal': true
        });
    }
    onHideRequestFormModal(){
        this.setState({
            'showRequestFormModal': false,
        });
        this.trigger(this.state);
    }

    onHandleRequestTextareaChange(value){
        this.setState({
            'requestTextareaValue': value
        });
    }

    requestData(token){
        const api = new API();
        let status = api.request_data(this.state.requestTextareaValue, token).status;
        this.setState({ responseStatus: status});
        if(status == 401)
            AuthActions.logout();

    }
}


class EquipmentListStore extends Reflux.Store {
    constructor(){
        super();
        this.listenables = EquipmentListActions;
        this.state = {
            equipments: []
        }
    }

    loadEquipments(){
        const api = new API();
        api.get_observatories().done(
            (data) => this.setState({equipments: data})
        );
    }
}

class EquipmentPlotStore extends Reflux.Store {
    constructor(){
        super();
        this.listenables = EquipmentPlotActions;
        this.resetState()
    }

    resetState(){
        this.state = {
            equipment: null,
            data: null,
            series: null,
            sel24h_series: null,
            showModal: false,
            showSel24hModal: false,
            plot_data: null,
            sel24h_plot_data: null,
            detailPlotData: null,
            depth: 0,
            spl: "63"
        }
    }

    setEquipment(eqp_id){
        this.resetState()
        this.setState({equipment: eqp_id})
    }

    setSPL(spl){
        this.setState({spl: spl})
    }

    addDepthFilter(depth){
        this.setState({depth: depth});
    }

    setSel24hModalData(data){
        this.setState({sel24h_plot_data:data, showSel24hModal: true})
    }

    closeSel24hModal(){
        this.setState({showSel24hModal:false});
    }

    loadData(spl, range){
        const api = new API();
        if(spl == undefined)
            spl = 'peak';
        if(spl == 'peak' || spl == '50p')
            api.get_spectrummatrixspl(this.state.equipment, this.state.depth, spl, range).done(
                (data) => this.setState({data:data})
            );
        else
            api.get_spectrummatrix(this.state.equipment, this.state.depth, spl, range).done(
                (data) => this.setState({data:data})
            );
        api.get_spectrumseries(this.state.equipment, this.state.depth, range).done(
            (data) => this.setState({series:_.values(data)[0]})
        );
        let current_equipment =  _.find(EquipmentListStore.state.equipments, obj => {
                return obj.id == this.state.equipment;
        });
        let data = api.get_sel24hseries(this.state.equipment, current_equipment.depths);
        this.setState({sel24h_series:data});

    }

    plotDetail(target_datetime, from_datetime, to_datetime){
        const api = new API();
        const filters = {
            from_datetime: from_datetime,
            to_datetime: to_datetime,
            mobile:false,
            ...(this.state.depth != 0 && {min_depth: this.state.depth, max_depth: this.state.depth})
        }
        api.get_spectrumobservatory(this.state.equipment, target_datetime, filters).done(
            (data) => { this.setState({plot_data:data,
                                     showModal:true})}
        );
    }

    closeModal(){
         this.setState({showModal:false});
    }

}


class AuthStore extends Reflux.Store {
    constructor(){
        super();
        // Listenable
        this.listenables = AuthActions;
        // Create variables
        this.token = null;
        this.error = null;
        this.registration_success = false;
        this.send_success = false;
        this.reset_success = false;
        this.loading = false;
        this.logged = false;
        this.user = null;
        // state
        this.state = {
            loading: this.loading,
            error: this.error,
            token: this.token,
            loggedin: this.logged,
            user: this.user,
            registration_success: this.registration_success,
            send_success: this.send_success,
            reset_success: this.reset_success,
        };
    }

    getState() {
        this.setState({
            loading: this.loading,
            error: this.error,
            token: this.token,
            loggedin: this.logged,
            user: this.user,
            registration_success: this.registration_success,
            send_success: this.send_success,
            reset_success: this.reset_success
        });
        return this.state;
    }


    changed(){
        this.trigger(this.getState());
    }

    userFromClaims() {
        return this.claims;
    }

    onLogin(email, password) {
        const url = `${Config.REST_API}rest-auth/login/`;
        const data = { email: email ,  password: password };
        this.loading = true;
        // Make login here
        $.ajax({
            url: url,
            data: data,
            crossDomain: true,
            type: 'POST'}).done(function(data, textStatus, jqXHR){
            AuthActions.login.completed(data);
        }).fail(function(data, textStatus){
            AuthActions.login.failed(data);
        });
    }

    onLoginFailed(authResponse){
        if(authResponse.responseJSON !== undefined){
            this.error = authResponse.responseJSON;
        }else
            this.error = {error: 'Something went wrong'};

        this.loading = false;
        this.changed();
    }

    onLoginCompleted(authResponse){
        if(authResponse){
            this.token = authResponse.key;
            this.error = null;
            this.logged = true;
            const api = new API();
            this.user = api.get_user_data(this.token).done(function(data){
                localStorage.setItem('permissions', encryptData(JSON.stringify(data.permissions)));
            });
            setCookie(2);
            localStorage.setItem('token', encryptData(this.token));
        } else {
            this.error = 'Username or password invalid.';
            this.logged = false;
        }
        this.loading = false;
        this.changed();
    }

    onRegistration(email, username, password1, password2) {
        const url = `${Config.REST_API}rest-auth/registration/`;
        const data = { username: username ,  password1: password1, password2: password2, email: email };
        this.loading = true;
        $.ajax({
            url: url,
            data: data,
            crossDomain: true,
            type: 'POST'}).done(function(data, textStatus, jqXHR){
            AuthActions.registration.completed();
        }).fail(function(data, textStatus){
            AuthActions.login.failed(data);
        });
    }

    onRegistrationCompleted(){
        this.registration_success = true;
        this.changed();
    }

    onResetPassword(uid, token, password1, password2) {
        const url = `${Config.REST_API}rest-auth/password/reset/confirm/`;
        const data = { uid: uid ,  new_password1: password1, new_password2: password2, token: token };
        this.loading = true;
        $.ajax({
            url: url,
            data: data,
            crossDomain: true,
            type: 'POST'}).done(function(data, textStatus, jqXHR){
            AuthActions.resetPassword.completed();
        }).fail(function(data, textStatus){
            AuthActions.login.failed(data);
        });
    }

    onResetPasswordCompleted(){
        this.reset_success = true;
        this.changed();
    }

    onSendEmail(email) {
        const url = `${Config.REST_API}rest-auth/password/reset/`;
        const data = { email: email };
        this.loading = true;
        $.ajax({
            url: url,
            data: data,
            crossDomain: true,
            type: 'POST'}).done(function(data, textStatus, jqXHR){
            AuthActions.sendEmail.completed();
        }).fail(function(data, textStatus){
            AuthActions.login.failed(data);
        });
    }


    onSendEmailCompleted(){
        this.send_success = true;
        this.changed();
    }

    onLogout() {
        localStorage.removeItem('token');
        localStorage.removeItem('exp');
        MapActions.clearDefaults();
        this.email = '';
        this.token = null;
        this.password = '';
        this.logged = false;
        this.error = false;
        this.loading = false;
        this.user = null;
        this.claims = null;
        this.trigger(this.getState());
    }

    onResetStates(){
        this.registration_success = false;
        this.loading = false;
        this.send_success = false;
        this.reset_success = false;
        this.changed();
    }
}

const flattenCodeList = (menu_status) => {
    let code_list =  _.map( _.filter(menu_status, {status:true}), 'code');
    return _.compact(code_list);
};

const buildMenuTree = (menu_status) => {
    // Shallow copy
    // const _menu_status = menu_status.map(a=>_.omit(a, 'status'));
    let menu_tree = _.groupBy(menu_status, 'equipment_type');
    _.forEach(menu_tree, (value, key) => {
        // Second level groupby campaign
        menu_tree[key] = _.groupBy(value, 'campaign');
    });
    return menu_tree;
};


class MapStore extends Reflux.Store {
    constructor(){
        super();
        /*
         * Listenables
         */
        // DownloadData
        this.listenTo(MapActions.downloadPathData, this.downloadPathData);
        this.listenTo(MapActions.loadInitialData, this.loadInitialData);
        // Map
        this.listenTo(MapActions.storeMap, this.storeMap);
        // Modals
        this.listenTo(MapActions.showModal, this.handleShowModal);
        this.listenTo(MapActions.hideModal, this.hideModal);
        this.listenTo(MapActions.getSpectrumData, this.getSpectrumData);
        // Filters
        this.listenTo(MapActions.filterDate, this.filterDate);
        this.listenTo(MapActions.filterLimit, this.filterLimit);
        this.listenTo(MapActions.changeLimit, this.changeLimit);
        this.listenTo(MapActions.selectMapOption, this.selectMapOption);
        this.listenTo(MapActions.applyFilters, this.applyFilters);
        this.listenTo(MapActions.addDepthFilter, this.addDepthFilter);
        this.listenTo(MapActions.addScaleFilter, this.addScaleFilter);
        this.listenTo(MapActions.addFilterEquipment, this.addFilterEquipment);
        this.listenTo(MapActions.removeFilterEquipment, this.removeFilterEquipment);
        this.listenTo(MapActions.clearDefaults, this.clearDefaults);
        // Raster
        this.listenTo(MapActions.loadRaster, this.loadRaster);
        this.listenTo(MapActions.loadRasterBand, this.loadRasterBand);
        this.listenTo(MapActions.selectBand, this.selectBand);
        this.listenTo(MapActions.selectRasterAPI, this.selectRasterAPI);

        /*
         * Attributes
         */
        this.map_options = ['path', 'density', 'spl', 'band', 'sel24h'];
        // load values
		this.initial_state = {
            band : null,
            campaigns : null,
            depthFilter : 0,
            scaleFilterSpl : 0,
            scaleFilterSel : 0,
            disableFilterButton: true,
            filters: {
                'lim': 50,
                'codes': [],
                'from_date': '2018-01-01',
                'to_date': moment(new Date()).format('Y-MM-DD'),
                'model': 'SA'
            },
            lim: 50,
            map : null,
            mapOption : 'path',
            menu_status: null,
            menu_tree: null,
            modalPlotElement : null,
            pablo : null,
            plot_data : null,
            rasterAPI : null,
            rasterData : null,
            showModal : false,
            animateModal: true,
            api:{
                'path' : null,
                'density': null,
                'spl': null,
                'band': null,
                'sel24h': null,
            },
            selection:{
                selection: false,
                rasterAPI: null,
                mapOption: null,
                depth: 0,
                scale: 0,
                customDepth: {min_depth: 500, max_depth: 1400},
                customScaleSpl: {min_scale: 50, max_scale: 170},
                customScaleSel: {min_scale: 50, max_scale: 170},
            },
            updateDepth: false,
            updateScale: false,
        };
		this.state = Object.assign({}, this.initial_state);
    }

    clearDefaults(){
		this.setState(this.initial_state);
    }

    addFilter(new_filter){
        let _filters = this.state.filters;
        _filters = _.extend({}, _filters, new_filter);
        this.setState({filters: _filters})
    }

    selectBand(band){
        var _api = this.state.api;
        _api['band'] = band;
        this.setState({band: band, api: _api, rasterAPI: band+'hz'});
    }

    selectRasterAPI(raster_api){
        var _api = this.state.api;
        _api[this.state.mapOption]  = raster_api;
        if(this.state.mapOption == 'spl')
            _api['band'] = null;
        this.setState({rasterAPI: raster_api, api: _api});
    }

    removeFilter(keys){
        _.each(keys, key => delete this.state.filters[key]);
        this.state.filters['disableFilterButton'] = false;
        this.setState(this.state);
        this.trigger(this.state);
    }

    downloadPathData(){
        let api = new API();
        this.setState({'pablo':null});
        const _this = this;
        api.get_path_data(this.state.filters).done(
            function(data){
                _this.setState({'pablo': data});
                _this.trigger(data);
            }
        );
    }

    loadInitialData(){
        // This function will create the array that will help the menu creation
        // Also, will build the initial filters and on success download the
        // path data for showing in the map.
        if(!_.isNull(this.state.menu_status)) return;// Perform only once
        const api = new API();
        const _this = this;
        api.get_equipments().done((data) => {
            // List of equipments! Key: Equipment Name/ Values: Details
            const equipments = data;
            const menu_status = _.flattenDeep(_.map(equipments,
                (details, name) =>  _.map(
                    details.campaigns,
                    campaign => _.assignIn({
                        name:name,
                        equipment_type:details.system,
                        status:true,
                        count:details.count

                    }, campaign))
                ));
            // Build menu tree
            // Equipment Type[] => Campaigns[] => Equipments
            // Set filters and download initial path data
            let filters = _.assign({}, this.state.filters); // shallow copy
            filters.codes = flattenCodeList(menu_status);
            _this.setState({
                filters: filters,
                menu_status: menu_status,
                menu_tree: buildMenuTree(menu_status)
            });
            this.downloadPathData();
        });
    }

    addScaleFilter(option, custom) {
        let map = '';
        let values = [];
        if (this.state.selection.mapOption === 'band') {
            map = 'Spl'
            values = [
                {min_scale: 70,max_scale: 130},
                {min_scale: 'auto',max_scale: 'auto'/*scale depending on max data*/},
            ];
        } else {
            map = 'Sel'
            values = [
                {min_scale: 150,max_scale: 190},
                {min_scale: 'auto',max_scale: 'auto'/*scale depending on max data*/},
            ];
        }
        this.setState({[`scaleFilter${map}`]: option});
        if(option == 2){
            let _filters = this.state.filters;
            _filters[`min_scale_${map}`] = custom.min_scale;
            _filters[`max_scale_${map}`] = custom.max_scale;
            this.addFilter(_filters);
        } else if(option == 1) {
            let _filters = this.state.filters;
            _filters[`min_scale_${map}`] = values[option].min_scale;
            _filters[`max_scale_${map}`] = values[option].max_scale;
            this.addFilter(_filters);
        } else {
            let _filters = this.state.filters;
            if (values[option]){
                _filters[`min_scale_${map}`] = values[option].min_scale;
                _filters[`max_scale_${map}`] = values[option].max_scale;
                this.addFilter(_filters);
            } else {
                this.removeFilter([`min_scale_${map}`, `max_scale_${map}`]);
            }
        }
    }

    addDepthFilter(option, custom){
        this.setState({depthFilter: option});
        const values = [
            {},
            {min_depth:0, max_depth:100},
            {min_depth:140, max_depth:300},
            {min_depth:850, max_depth:1100},
        ];
        if(option == 4){
            let _filters = this.state.filters;
            _filters['min_depth'] = custom.min_depth;
            _filters['max_depth'] = custom.max_depth;
            this.addFilter(_filters);
        } else{
            let _filters = this.state.filters;
            if (values[option]){
                _filters['min_depth'] = values[option].min_depth;
                _filters['max_depth'] = values[option].max_depth;
                this.addFilter(_filters);
            } else {
                this.removeFilter(['min_depth', 'max_depth']);
            }
        }
    }


    addFilterEquipment(eqp_code){
        let menu_status = this.state.menu_status;
        let menu_item_idx = _.findIndex(menu_status, { code:eqp_code});
        if (menu_item_idx >= 0 ){
            menu_status[menu_item_idx].status = true;
            this.setState({menu_status: menu_status, menu_tree: buildMenuTree(menu_status)});
            this.addFilter({'codes': flattenCodeList(menu_status)});
        }
    }

    removeFilterEquipment(eqp_code){
        let menu_status = this.state.menu_status;
        let menu_item_idx = _.findIndex(menu_status, {code:eqp_code});
        if (menu_item_idx >= 0 ){
            menu_status[menu_item_idx].status = false;
            this.setState({menu_status: menu_status, menu_tree: buildMenuTree(menu_status)});
            this.addFilter({codes: flattenCodeList(menu_status)});
        }
    }

    filterDate(filter_date, dtype='from_date'){
        if(_.indexOf(['from_date', 'to_date'], dtype) < 0){
            return;
        }
        let new_filter = {};
        new_filter[dtype] = filter_date;
        this.addFilter(new_filter);
    }

    filterLimit(filter_limit){
        this.addFilter({'lim': filter_limit});
    }

    changeLimit(limit){
        this.setState({lim: limit});
        this.trigger(this.state);
    }

    applyFilters(){
        this.setState({rasterData: null, loading: true, updateDepth: true,updateScale: true});
        let api = null;
        var default_value = this.state.api[this.state.mapOption]
        if(this.state.mapOption == 'spl' && this.state.api['band'] != null)
            this.state.mapOption = 'band';
        let _selection = this.state.selection;
        _selection['mapOption'] = this.state.mapOption;
        switch(this.state.mapOption){
            case 'density':
                api = this.state.rasterAPI;
                if (['density', 'traffic'].indexOf(api) < 0 || !api)
                    api = default_value || 'density';
                this.loadRaster(api);
                break;
            case 'spl':
                api = this.state.rasterAPI;
                if (['density', 'traffic', 'SEL24h'].indexOf(api) > -1 || !api)
                    api = default_value || '63hz';
                this.loadRaster(api);
                break;
            case 'band':
                this.loadRasterBand();
                break;
            case 'path':
                this.downloadPathData();
                break;
            case 'sel24h':
                this.removeFilter(['min_depth', 'max_depth']);
                this.loadRaster('SEL24h');
                break;
            default:
                break;
        }
        this.setState({loading: false, 'selection': _selection});
    }

    selectMapOption(option){
        if ( this.map_options.indexOf(option) > -1 ) {
            this.setState({mapOption: option});
        }
    }

    loadRaster(endpoint){
        const _this = this;
        let _selection = this.state.selection;
        _selection['rasterAPI'] = endpoint;
        _selection['selection'] = endpoint;
        this.setState({'rasterAPI': endpoint, 'selection': _selection});
        const api= new API();
        api.get_raster(endpoint, this.state.filters).done(
            function(data){
                _this.setState({'rasterData': data});
            });
        this.trigger(this.state);
    }


    loadRasterBand(){
        let _filters = this.state.filters;
        const _this = this;
        _filters['frequency'] = ((this.state.api['band'] || this.state.band) || '63');
        let _selection = this.state.selection;
        _selection['rasterAPI'] = ((this.state.api['band'] || this.state.band) || '63');
        _selection['selection'] = ((this.state.api['band'] || this.state.band) || '63')+'hz';
        this.setState({rasterAPI: `${this.state.band}hz`, filters: _filters, 'selection': _selection});
        const api= new API();
        api.get_raster('band', _filters).done(
            function(data){
                _this.setState({'rasterData': data});
            });
        this.setState({'mapOption': 'band'});
        this.trigger(this.state);
    }

    handleShowModal(lat, lng){
        this.setState({
            plot_data: null,
            showModal: true,
            lat: lat,
            lng:lng});
    }

    hideModal(){
        let _filters = this.state.filters;

        this.setState({
            showModal: false,
            lat:null,
            lng:null,
            filters: _filters
        });
        PlotMapActions.clearData();
    }

    storeMap(map){
        this.setState({'map': map});
    }

    getSpectrumData(lat, lng, depth, animate=false){
        const api = new API();
        let _data = null;
        let filters = this.state.filters;
        if(depth != undefined){
            filters.max_depth = depth + 0.1;
            filters.min_depth = depth - 0.1;
        }
        api.get_spectrumlocation(lat, lng, filters).done(function(data){
            _data = data || [];
        });

        if(_data !== null){
            this.setState({
                'showModal': true,
                'animateModal': animate,
                'lat': lat,
                'lng': lng,
                'modalPlotElement': _data[0]['eq_serial'],
                'plot_data': _data
            });
        }
    }


}


class WeatherStore extends Reflux.Store {
    constructor(){
        super();
        // Listenable
        this.listenables = WeatherActions;
        // state
        this.state = {
            timestamp: moment().format('Y-MM-DD'),
            wind_data: null,
            wave_data: null
        };
    }

    changed(){
        this.trigger(this.getState());
    }

    onGetWeatherData(timestamp, type){
        const api = new API();
        api.get_weather(timestamp, type).done(function(data, textStatus, jqXHR){
            WeatherActions.getWeatherData.completed(data, type);
        });

    }

    onGetWeatherDataCompleted(data, type){

        if(data !== null){
            type == 'wind' ? this.setState({ 'wind_data': data }) : this.setState({ 'wave_data': data });
        }
    }
}


export {
    MapStore,
    AuthStore,
    MenuStore,
    EquipmentListStore,
    EquipmentPlotStore,
    WeatherStore
};
