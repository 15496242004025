import Leaflet from 'leaflet';
import React from 'react';
import Main from './Main';


Leaflet.Icon.Default.imagePath = '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.2.0/images/';

const App = () => (
        <Main />
);

export default App;
