/* Main Panel */
import React from 'react';
import Reflux from 'reflux';
import {
    FormGroup,
    ControlLabel,
    FormControl,
    Radio,
    ButtonToolbar,
    ToggleButtonGroup,
    ToggleButton,
} from 'react-bootstrap';
import moment from 'moment';

import {LayersControl, FeatureGroup, TileLayer} from 'react-leaflet';

import SimmaoMapComponent from './Map';
import Header from '../twist_header/Header';
import _ from 'lodash';

import PlotModal from './PlotModal';
import {RasterComponent, EquipmentPathComponent} from './Path.js'
import {
    BathymetryComponent,
    AGBSComponent,
    BayComponent,
    DuctsComponent,
    FlexibleDuctsComponent,
    FiberOpticsComponent,
    PipelineComponent,
    SubseaObservatoryComponent,
    MooringLinesComponent,
    PlatformsComponent,
} from './PanelMapData'

import WeatherLayer from './weather'
import LayersControl2, { ControlledLayerItem } from "./LayerControl";
// TWIST Stores
import {MapStore, WeatherStore} from '../../store';
import {MapActions} from '../../actions';

// Style
import styles from './Panel.css';

//Slider
import { Slider, InputNumber, Button, Tree, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import "!style-loader!css-loader!antd/dist/antd.css";

var Config = require('Config');
var show_sel24h = Config.SHOW_SEL24H != undefined ? Config.SHOW_SEL24H : true;
import {decryptData} from '../../crypto'

const { TreeNode } = Tree;

class PathFilters extends Reflux.Component {
    constructor (props, context){
        super(props, context);
        this.state = {min_depth: 0, max_depth: 0};
        this.store = MapStore;
        this.storeKeys = ['lim', 'selection', 'mapOption'];
    }

    onChangeLimit(event){
        let fieldVal = event.target.value;
        let _selection = this.state.selection;
        _selection['mapOption'] = 'path';
        _selection['rasterAPI'] = null;
        _selection['selection'] = null;
        this.setState({selection: _selection});
        MapActions.changeLimit(fieldVal);
        this.props.updateStateCallBack(false);
    }

    render(){
        if (this.state.mapOption!= 'path'){
            return null;
        }
        return (<FormGroup controlId="formControlsSelect">
            <br />
            <ControlLabel>{'Número de pontos'}</ControlLabel>
            <FormControl
                onChange={this.onChangeLimit.bind(this)}
                componentClass="select"
                placeholder="Limite"
                value={ this.state.lim }
            name='filterLimit'>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                <option value="10000">10000</option>
            </FormControl>
        </FormGroup>
        );
    }
}

class DepthSelector extends Reflux.Component{
    constructor(props, context) {
        super(props, context);
        this.state={min_depth: null, max_depth: null}
        this.store = MapStore;
        this.storeKeys = ['selection', 'depthFilter', 'updateDepth', 'filters'];
        this.prevSelection= 0;
    }

    onChangeDepth(e){
        let radio_value = e.target.value;
        let _selection = this.state.selection;
        _selection['depth'] = radio_value;
        if(_selection['mapOption'] == null)
            _selection['mapOption'] = this.props.mapOption;
        this.setState({selection: _selection});
        this.props.updateStateCallBack(false);
    }


    updateDepths(){
        let depths = [
            {min_depth:0, max_depth:1100},
            {min_depth:0, max_depth:100},
            {min_depth:140, max_depth:300},
            {min_depth:850, max_depth:1100},
            {min_depth:this.state.filters.min_depth, max_depth:this.state.filters.max_depth},
        ];

        if(this.state.max_depth !==  depths[this.state.depthFilter].max_depth || this.state.min_depth !==  depths[this.state.depthFilter].min_depth){
            let _selection = this.state.selection;
            _selection['depth'] = this.state.depthFilter;
            this.setState({
                max_depth: depths[this.state.depthFilter].max_depth,
                min_depth: depths[this.state.depthFilter].min_depth,
                selection: _selection
            })
        }
    }

    componentDidMount(){
        this.id = _.uniqueId("slider-");
        this.updateDepths();
    }

    componentDidUpdate(prevProps, prevState){
        if(this.state.updateDepth){
            this.id = _.uniqueId("slider-");
            this.updateDepths();
            this.setState({updateDepth: false});
        }
    }
    render(){
        let min_depth = this.state.min_depth;
        let max_depth = this.state.max_depth;
        const id = this.id
        return (
            <FormGroup>
                <Radio onChange={this.onChangeDepth.bind(this)}  name="radioGroupDeep" checked={this.state.selection['depth'] == 0} value={0} inline>Todas</Radio><br />
                <Radio onChange={this.onChangeDepth.bind(this)}  name="radioGroupDeep" checked={this.state.selection['depth'] == 1} value={1} inline>0 a 100 m</Radio><br />
                <Radio onChange={this.onChangeDepth.bind(this)}  name="radioGroupDeep" checked={this.state.selection['depth'] == 2} value={2} inline>140 a 300 m</Radio><br />
                <Radio onChange={this.onChangeDepth.bind(this)}  name="radioGroupDeep" checked={this.state.selection['depth'] == 3} value={3} inline>850 a 1100 m</Radio><br />
                <Radio onChange={this.onChangeDepth.bind(this)}  name="radioGroupDeep" checked={this.state.selection['depth'] == 4} value={4} inline className={styles.depthContainer}>Personalizado</Radio>
                <div className= {styles.customDepthContainer}>
                    <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                        <div style={{ marginRight: 10}}>{"Mín: "}</div>
                        <InputNumber key={this._reactInternalFiber.key + '-input1'}
                            defaultValue={this.state.min_depth}
                            min={0}
                            max={this.state.max_depth}
                            disabled={this.state.selection['depth'] != 4}
                            formatter={value => `${value}m`}
                            parser={value => value.replace(/\D/g, '')}
                            value={this.state.min_depth}
                            onChange={value => {
                                let _selection = this.state.selection;
                                _selection['customDepth'].min_depth = value > this.state.max_depth ? this.state.max_depth : value;
                                this.setState({selection: _selection, min_depth: value > this.state.max_depth ? this.state.max_depth : value});
                                this.props.updateStateCallBack(false);
                            }}
                        />
                        <div style={{marginLeft: 15, marginRight: 10}}>{"Máx: "}</div>
                        <InputNumber key={this._reactInternalFiber.key + '-input2'}
                            defaultValue={this.state.max_depth}
                            min={this.state.min_depth}
                            max={1100}
                            disabled={this.state.selection['depth'] != 4}
                            formatter={value => `${value}m`}
                            parser={value => value.replace(/\D/g, '')}
                            value={this.state.max_depth}
                            onChange={value => {
                                let _selection = this.state.selection;
                                _selection['customDepth'].max_depth = value < this.state.min_depth ? this.state.min_depth : value;
                                this.setState({selection: _selection, max_depth: value < this.state.min_depth ? this.state.min_depth : value});
                                this.props.updateStateCallBack(false);
                            }}
                        />
                    </div>
                    <Slider key={id}
                        min={0}
                        max={1100}
                        marks={{
                            0: `0m`,
                            1100: `1100m`
                        }}
                        allowCross = {false}
                        disabled={this.state.selection['depth'] != 4}
                        value={[min_depth, max_depth]}
                        onChange={value => {
                            this.setState({min_depth: value[0], max_depth: value[1]})
                        }}
                        onAfterChange={value => {
                            let _selection = this.state.selection;
                            _selection['customDepth'] = {min_depth: value[0], max_depth: value[1]};
                            this.id = _.uniqueId("slider-");
                            this.setState({selection: _selection});
                            this.props.updateStateCallBack(false);
                        }}
                        tooltipVisible={false} range
                    />
                </div>
            </FormGroup>
        );
    }

}

class ScaleSelector extends Reflux.Component {
    constructor(props,context) {
        super(props, context);
        this.state = {min_scale_Spl: null, max_scale_Spl: null,min_scale_Sel: null, max_scale_Sel: null};
        this.store = MapStore;
        this.storeKeys = ['selection', 'scaleFilterSel', 'scaleFilterSpl', 'updateScale', 'filters'];
    }

    onChangeScale(e) {
        let radio_value = e.target.value;
        let _selection = this.state.selection;
        _selection['scale'] = radio_value;
        if(_selection['mapOption'] == null)
            _selection['mapOption'] = this.props.mapOption;
        this.setState({selection: _selection});
        this.props.updateStateCallBack(false);
    }

    updateScale() {
        let map ='';
        if (this.state.selection['mapOption'] === 'band') {
            map = 'Spl'
        } else {
            map = 'Sel'
        }
        let scales = [
            {min_scale: 70,max_scale: 130},
            {min_scale: 50,max_scale: 170},
            {min_scale: this.state.filters[`min_scale_${map}`] , max_scale: this.state.filters[`max_scale_${map}`]}
        ];
        if(this.state[`max_scale_${map}`] !==  scales[this.state[`scaleFilter${map}`]].max_scale || this.state[`min_scale_${map}`] !==  scales[this.state[`scaleFilter${map}`]].min_scale){
            let _selection = this.state.selection;
            _selection['scale'] = this.state[`scaleFilter${map}`];

            this.setState({
                [`max_scale_${map}`]: scales[this.state[`scaleFilter${map}`]].max_scale,
                [`min_scale_${map}`]: scales[this.state[`scaleFilter${map}`]].min_scale,
                selection: _selection
            })
        }


    }

    componentDidMount(){
        this.updateScale();
    }

    componentDidUpdate(prevProps, prevState){
        if(this.state.updateScale){
            this.updateScale();
            this.setState({updateScale: false});
        }
    }

    render() {
        return (
            <FormGroup>
                <Radio onChange={this.onChangeScale.bind(this)} checked={this.state.selection['scale'] == 0} name="radioGroupScale" value={0} inline>Padrão</Radio><br />
                <Radio onChange={this.onChangeScale.bind(this)} checked={this.state.selection['scale'] == 1} name="radioGroupScale" value={1} inline>Automático</Radio><br />
                <Radio onChange={this.onChangeScale.bind(this)} checked={this.state.selection['scale'] == 2} name="radioGroupScale" value={2} inline className={styles.depthContainer}>Personalizado</Radio>
                <div className= {styles.customDepthContainer}>
                    <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                        <div style={{ marginRight: 10}}>{"Mín: "}</div>
                        <InputNumber key={this._reactInternalFiber.key + '-input3'}
                            defaultValue={this.state.selection['mapOption'] === 'band' ? this.state.min_scale_Spl : this.state.min_scale_Sel}
                            min={0}
                            max={this.state.selection['mapOption'] === 'band' ? this.state.max_scale_Spl : this.state.max_scale_Sel}
                            disabled={this.state.selection['scale'] != 2}
                            parser={value => value.replace(/\D/g, '')}
                            value={this.state.selection['mapOption'] === 'band' ? this.state.min_scale_Spl : this.state.min_scale_Sel}
                            onChange={value => {
                                let map = '';
                                if (this.state.selection['mapOption'] === 'band') {
                                    map = 'Spl';
                                }else {
                                    map = 'Sel'
                                }
                                let _selection = this.state.selection;
                                _selection[`customScale${map}`].min_scale = value > this.state[`max_scale_${map}`] ? this.state[`max_scale_${map}`] : value;
                                this.setState({selection: _selection, [`min_scale_${map}`]: value > this.state[`max_scale_${map}`] ? this.state[`min_scale_${map}`] : value});
                                this.props.updateStateCallBack(false);
                            }}
                        />
                        <div style={{marginLeft: 15, marginRight: 10}}>{"Máx: "}</div>
                        <InputNumber key={this._reactInternalFiber.key + '-input4'}
                            defaultValue={this.state.selection['mapOption'] === 'band' ? this.state.max_scale_Spl : this.state.max_scale_Sel}
                            min={this.state.selection['mapOption'] === 'band' ? this.state.min_scale_Spl : this.state.min_scale_Sel}
                            max={1800}
                            disabled={this.state.selection['scale'] != 2}
                            parser={value => value.replace(/\D/g, '')}
                            value={this.state.selection['mapOption'] === 'band' ? this.state.max_scale_Spl : this.state.max_scale_Sel}
                            onChange={value => {
                                let map = '';
                                if (this.state.selection['mapOption'] === 'band') {
                                    map = 'Spl';
                                }else {
                                    map = 'Sel'
                                }
                                let _selection = this.state.selection;
                                _selection[`customScale${map}`].max_scale = value < this.state[`min_scale_${map}`] ? this.state[`min_scale_${map}`] : value;
                                this.setState({selection: _selection, [`max_scale_${map}`]: value < this.state[`min_scale_${map}`] ? this.state[`min_scale_${map}`] : value});
                                this.props.updateStateCallBack(false);
                            }}
                        />
                    </div>
                </div>
            </FormGroup>
        )
    }
}

class DensityFilters extends Reflux.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {min_depth: 0, max_depth: 0};
        this.store = MapStore;
        this.id = _.uniqueId("densityFilter-");
    }

    onChange(e){
        let radio_value = e.target.value;
        let _selection = this.state.selection;
        _selection['selection'] = radio_value;
        _selection['mapOption'] = 'density';
        _selection['rasterAPI'] = radio_value;
        this.setState({selection: _selection});
        this.props.updateStateCallBack(false);
   }

    render(){
        const id = this.id;
        if (this.state.mapOption!= 'density')
            return null;
        let component = (<div>
            <br />
            <ControlLabel>{'Selecione o tipo de equipamento:'}</ControlLabel>
            <FormGroup>
                <Radio onChange={this.onChange.bind(this)} checked={this.state.selection['selection'] === 'density'} name="density" value="density" inline>{'Instrumentação'}</Radio><br />
                <Radio onChange={this.onChange.bind(this)} checked={this.state.selection['selection'] === 'traffic'} name="density" value="traffic" inline>{'Embarcações(AIS)'}</Radio><br />
            </FormGroup>
            { this.state.selection['selection'] !== 'traffic' &&
                <React.Fragment>
                    <ControlLabel>{'Profundidade:'}</ControlLabel>
                    <DepthSelector key={id} updateStateCallBack={this.props.updateStateCallBack} mapOption={'density'}/>
                </React.Fragment>
            }
        </div>
        );
        return component;
    }
}


class SPLFilters extends Reflux.Component {
    constructor (props, context){
        super(props, context);
        this.state = {min_depth: 0, max_depth: 0};
        this.store = MapStore;
        this.id = _.uniqueId("splFilter-");

    }

    onChange(e){
        let radio_value = e.target.value;
        let _selection = this.state.selection;
        _selection['selection'] = radio_value;
        _selection['mapOption'] = 'spl';
        _selection['rasterAPI'] = radio_value;
        this.setState({selection: _selection});
        this.props.updateStateCallBack(false);
    }

    onChangeBand(e){
        let radio_value = e.target.value;
        let _selection = this.state.selection;
        _selection['selection'] = radio_value+'hz';
        _selection['mapOption'] = 'band';
        _selection['rasterAPI'] = radio_value;
        this.setState({selection: _selection});
        this.props.updateStateCallBack(false);
    }


    render(){
        const id = this.id;
        if (this.state.mapOption!= 'spl' && this.state.mapOption!= 'band')
            return null;
        let component = (
            <div>
                <br />
                <ControlLabel>SPL</ControlLabel>
                <FormGroup>
                    <Radio onChange={this.onChange.bind(this)} checked={this.state.selection['selection'] === 'mean_total'} name="spl" value="mean_total" inline>RMS (total)</Radio><br />
                    <Radio onChange={this.onChange.bind(this)} checked={this.state.selection['selection'] === 'mean'} name="spl" value="mean" inline>RMS (10Hz à 10kHz)</Radio><br />
                    <Radio onChange={this.onChange.bind(this)} checked={this.state.selection['selection'] === 'peak_total'} name="spl" value="peak_total" inline>Pico-a-pico (total)</Radio><br />
                    <Radio onChange={this.onChange.bind(this)} checked={this.state.selection['selection'] === 'peak'} name="spl" value="peak" inline>Pico-a-pico (10Hz a 10kHz)</Radio><br />
                    <Radio onChange={this.onChangeBand.bind(this)} checked={this.state.selection['selection'] === '63hz'} name="spl" value="63" inline>63 Hz</Radio><br />
                    <Radio onChange={this.onChangeBand.bind(this)} checked={this.state.selection['selection'] === '125hz'} name="spl" value="125" inline>125 Hz</Radio><br />
                    <Radio onChange={this.onChangeBand.bind(this)} checked={this.state.selection['selection'] === '500hz'} name="spl" value="500" inline>500 Hz</Radio><br />
                    <Radio onChange={this.onChangeBand.bind(this)} checked={this.state.selection['selection'] === '1000hz'} name="spl" value="1000" inline>1000 Hz</Radio><br />
                </FormGroup>
                <ControlLabel>Profundidade</ControlLabel>
                <DepthSelector key={id} mapOption='spl' updateStateCallBack={this.props.updateStateCallBack}/>
                <ControlLabel>Escala de Cores</ControlLabel>
                <ScaleSelector key={id + 2} mapOption='spl' updateStateCallBack={this.props.updateStateCallBack}/>
            </div>
        );
        return component;
    }
}

class SPL24hFilters extends Reflux.Component {
    constructor (props, context){
        super(props, context);
        this.store = MapStore;
        this.id = _.uniqueId("sel24hFilter-");

    }

    onChange(e){
        let _selection = this.state.selection;
        _selection['mapOption'] = 'sel24h';
        this.setState({selection: _selection});
        this.props.updateStateCallBack(false);
    }

    render(){
        const id = this.id;
        if (this.state.mapOption != 'sel24h')
            return null;
        let component = (
            <div>
                <br />
                <ControlLabel>Escala de Cores</ControlLabel>
                <ScaleSelector key={id + 1} mapOption='sel24h' updateStateCallBack={this.props.updateStateCallBack}/>
            </div>
        );
        return component;
    }
}

class EquipmentTree extends Reflux.Component{
    constructor (props, context){
        // Init class
        super(props, context);
        this.state = {defaultChecked: [], tree: []};
        this.store = MapStore;
        this.storeKeys = ['menu_tree', 'menu_status'];
        this.equipment_type = props.equipmentType;
    }

    componentWillMount(){
        if (this.store.state.menu_tree == null) MapActions.loadInitialData();
    }

    componentDidMount(){
        let menu_tree = this.store.state.menu_tree;
        let campaign_obj = null;
        let type = null;
        let device_obj = null;
        let defaultChecked = this.state.defaultChecked;
        let tree = this.state.tree;
        _.map(menu_tree, (campaigns, equipment_type) => {
            type = {title: equipment_type, key: _.uniqueId(equipment_type), children: []};
            _.map(campaigns, (devices, campaign) => {
                campaign_obj = {title: 'Campanha ' + campaign, key: _.uniqueId(campaign), children: []};
                _.map(devices, device => {
                    device_obj = {title: device.name, key: device.code};
                    if(device.status)
                        defaultChecked.push(device.code);
                    campaign_obj.children.push(device_obj)
                });
                type.children.push(campaign_obj)
            });
            tree.push(type);
        });
        this.setState({
            tree: tree,
            defaultChecked: defaultChecked,
            checked: defaultChecked
        })

    }

    renderTreeNodes(data){
        return data.map((item) => {
            if (item.children) {
                return (
                  <TreeNode title={item.title} key={item.key} dataRef={item}>
                    {this.renderTreeNodes(item.children)}
                  </TreeNode>
                );
              }
              return <TreeNode {...item} />;
            })

    }

    onCheck(checkedKeys, info){
        let new_checks = (info.checked == false ? _.difference(this.state.checked, checkedKeys) : _.difference(checkedKeys, this.state.checked));
        new_checks.map(check => {
            if(check.includes('__') && info.checked){
                MapActions.addFilterEquipment(check);
            }else if(check.includes('__') && !info.checked){
                MapActions.removeFilterEquipment(check);
            }
            this.props.updateStateCallBack(false);
        });
        this.setState({checked: checkedKeys})
    }


    render(){
        let tree = this.state.tree;
        if (!tree.length) return null;
        return (
            <Tree
              checkable
              defaultCheckedKeys={this.state.defaultChecked}
              onCheck={this.onCheck.bind(this)}
            >
                {this.renderTreeNodes(tree)}
            </Tree>
          );
    }
}

class LoadingComponent extends Reflux.Component {
    constructor(props, context){
        super(props, context);
    }

    render(){
        return (
                <div className={styles.loadingModal} >
                    <h2>Carregando...</h2>
                </div>
        );
    }
}

class FilterButtonComponent extends Reflux.Component {
    constructor(props){
        super(props);
        this.state = {};
        this.store = MapStore;
        this.storeKeys = ['filter', 'disableFilterButton', 'selection', 'lim', 'updateDepth', 'updateScale'];
    }

    componentDidMount(){
        this.setState({loading: false});
    }

    handleClick(){
        this.setState({loading: true});
        setTimeout(function(){
            switch(this.state.selection['mapOption']){
                case 'band':
                    MapActions.selectMapOption('band');
                    MapActions.selectBand(this.state.selection['rasterAPI']);
                    MapActions.addDepthFilter(this.state.selection['depth'], this.state.selection.customDepth);
                    MapActions.addScaleFilter(this.state.selection['scale'], this.state.selection.customScaleSpl);
                    break;
                case 'density':
                    MapActions.selectMapOption('density');
                    MapActions.selectRasterAPI(this.state.selection['rasterAPI']);
                    MapActions.addDepthFilter(this.state.selection['depth'], this.state.selection.customDepth);
                    break;
                case 'spl':
                    MapActions.selectMapOption('spl');
                    MapActions.selectRasterAPI(this.state.selection['rasterAPI']);
                    MapActions.addDepthFilter(this.state.selection['depth'], this.state.selection.customDepth);
                    break;
                case 'path':
                    MapActions.selectMapOption('path');
                    MapActions.filterLimit(this.state.lim);
                    break;
                case 'sel24h':
                    MapActions.selectMapOption('sel24h');
                    MapActions.addScaleFilter(this.state.selection['scale'], this.state.selection.customScaleSel);
                    break;
            }
            MapActions.applyFilters();
            this.props.updateStateCallBack(true);
            this.setState({loading: false});
        }.bind(this), 500)
    }

    render(){
        return (<div><Button
                type="primary"
                className={styles.filterButton + ' ' + (this.props.enable ? styles.disabled : '') }
                loading={this.state.loading}
                onClick={this.handleClick.bind(this)}>
                    {'Aplicar'}
                </Button>
                {this.state.loading != false &&
                <LoadingComponent loading={this.state.loading}/>
                }</div>)
    }
}

class SideBarComponent extends Reflux.Component {
    constructor (props, context){
        super(props, context);
        this.state = {enableFilterButton: true};
        this.store = MapStore;
        this.updateState = this.updateState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.sel24h_permission = JSON.parse(decryptData(localStorage.getItem('permissions'))).includes('view_sel24h');

    }

    componentDidMount(){
        this.setState({loading: false});
    }

    onChangeDate(value, dtype){
        MapActions.filterDate(value, dtype);
        this.setState({enableFilterButton:false});
    }

    selectMapOption(e){
        this.setState({loading: true});
        setTimeout(function(){
            MapActions.selectMapOption(e);
            MapActions.applyFilters();
        },500);

    }

    getDateFieldValue(field){
        if(field in this.state.filters)
            return this.state.filters[field];
        return '';
    }

    updateState(value){
        this.setState({enableFilterButton: value});
    }

    handleChange(e){
        this.selectMapOption(e);
        this.setState({enableFilterButton:true});
    }

    render(){
        let calendarDivStyle = {
            maxWidth: '170px', marginLeft: 20
        };


        return (
            <div id="sidebar-menu" className={styles.sideBarMenuContainer} >
                <FilterButtonComponent enable={this.state.enableFilterButton} updateStateCallBack={this.updateState}/>
                <div className={styles.panelGroup}>
                    <FormGroup>
                        <ControlLabel>{'Data Inicial'}</ControlLabel>
                            <DatePicker
                                locale={locale}
                                value={moment(this.getDateFieldValue('from_date'))}
                                onChange={(date, dateString) => this.onChangeDate(dateString, 'from_date')}
                                disabledDate={(current) => {return (current < moment('2015-11-12') || current > moment().endOf('day') /* trocar isso aqui para ser de acordo com os dados */)}}
                                allowClear={false}
                                style={calendarDivStyle}
                            />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel style={{width: 75}}>{'Data final'}</ControlLabel>
                            <DatePicker
                                locale={locale}
                                value={moment(this.getDateFieldValue('to_date'))}
                                onChange={(date, dateString) => this.onChangeDate(dateString, 'to_date')}
                                disabledDate={(current) => {return (current < moment('2015-11-12') || current > moment().endOf('day'))}}
                                allowClear={false}
                                style={calendarDivStyle}
                            />
                    </FormGroup>
                    <br />
                    <ButtonToolbar>
                        <ToggleButtonGroup
                            type="radio"
                            name="mapOptions"
                            onChange={this.handleChange}
                            defaultValue={this.state.mapOption == 'spl' ? 'band' : this.state.mapOption}>
                            <ToggleButton value="path">{'Trajetórias'}</ToggleButton>
                            <ToggleButton value="density">{'Amostras'}</ToggleButton>
                            <ToggleButton value="band">{'SPL'}</ToggleButton>
                            {show_sel24h && this.sel24h_permission &&
                                <ToggleButton value="sel24h">{'SEL 24h'}</ToggleButton>
                            }
                        </ToggleButtonGroup>
                    </ButtonToolbar>
                    <PathFilters updateStateCallBack={this.updateState}/>
                    <SPLFilters key='splFilters' updateStateCallBack={this.updateState}/>
                    <DensityFilters key='densityFilters' updateStateCallBack={this.updateState}/>
                    <SPL24hFilters key='spl24hFilters' updateStateCallBack={this.updateState}/>

                    {this.state.mapOption != 'sel24h' &&
                    !(this.state.mapOption  == 'density' && this.state.selection['selection']  == 'traffic') &&
                        <div>
                            <ControlLabel>{'Equipamentos'}</ControlLabel>
                            <EquipmentTree updateStateCallBack={this.updateState}/>
                        </div>
                    }
                </div>
                {this.state.loading != false &&
                    <LoadingComponent/>
                }
            </div>
        );
    }
}

class MapIndication extends Reflux.Component{
    constructor(props){
        super(props);
        this.state = {};
        this.store = MapStore;
        this.content = null;
        this.depth = null;
    }

    render(){
        const depths = [
            'Todas',
            '0 a 100m',
            '140 a 300m',
            '850 a 1100m',
            this.state.filters.min_depth !== undefined ? (this.state.filters.min_depth+' a '+this.state.filters.max_depth+'m') : ''
        ]

        switch(this.state.mapOption){
            case 'path':
                this.content = 'Nº de pontos: '+ this.state.filters.lim
                this.depth = null;
                break;
            case 'density':
                this.content = 'Tipo de equipamento: '+ (this.state.rasterAPI == 'traffic' ? 'Embarcações (AIS)' : 'Instrumentação');
                this.depth = this.state.rasterAPI == 'traffic' ? null : ('Profundidade: '+ depths[this.state.depthFilter]);
                break;
            case 'spl':
                this.content = 'SPL: '+ (this.state.rasterAPI == 'peak' ? 'Pico-a-pico' : 'RMS');
                this.depth = 'Profundidade: '+ depths[this.state.depthFilter];
                break;
            case 'band':
                this.content = 'SPL: '+ this.state.rasterAPI;
                this.depth = 'Profundidade: '+ depths[this.state.depthFilter];
                break;
            case 'sel24h':
                this.content = 'SEL 24h';
                this.depth = null;
                break;
        }

        return(
            <div className={styles.mapIndication}>
                Data inicial: {this.state.filters.from_date}<br/>
                Data final: {this.state.filters.to_date}<br/>
                {this.content}<br/>
                {this.depth}
            </div>
        )
    }
}


class PanelContent extends Reflux.Component{
    constructor(props){
        super(props);
        this.state = {};
        this.stores = [MapStore, WeatherStore];

    }
    render(){
        return(
            <div className={styles.wrapper}>
                <SideBarComponent />
                <SimmaoMapComponent small={false} modal={true}>
                    <FeatureGroup><RasterComponent key={_.uniqueId('raster-component')}/></FeatureGroup>
                    <FeatureGroup><EquipmentPathComponent/></FeatureGroup>
                    <LayersControl2 position="bottomright">
                        {/*
                        <ControlledLayerItem checked={false || this.state.wind_data} disabled={ !this.state.wind_data } autoCheck name="Vento" group="Clima">
                            <FeatureGroup>
                                <WeatherLayer displayValues={true} data={this.state.wind_data} maxVelocity={15} colorScale={['#0d0887', '#46039f', '#7201a8', '#9c179e', '#bd3786', '#d8576b', '#ed7953', '#fb9f3a', '#fdca26', '#f0f921']}/>
                                <TileLayer
                                    attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors |
                                    Derived product from the <a href=&quot;https://www.gebco.net/data_and_products/gridded_bathymetry_data/&quot;>GEBCO 2019 Grid</a>, made with <a href=&quot;https://www.naturalearthdata.com/&quot;>NaturalEarth</a> by <a href=&quot;https://opendem.info/&quot;>OpenDEM</a>"
                                    url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
                                />
                            </FeatureGroup>
                        </ControlledLayerItem>
                        <ControlledLayerItem checked={false} disabled={!this.state.wave_data} name="Onda" group="Clima">
                            <FeatureGroup>
                                <WeatherLayer displayValues={false} data={this.state.wave_data} minVelocity={5} maxVelocity={6} lineWidth={10} colorScale={["#440154FF", '#453781FF', "#404788FF", "#39568CFF", "#2D708EFF", "#238A8DFF", "#20A387FF", "#3CBB75FF", "#73D055FF", "#B8DE29FF", "#FDE725FF"]}/>
                                <TileLayer
                                    attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors |
                                    Derived product from the <a href=&quot;https://www.gebco.net/data_and_products/gridded_bathymetry_data/&quot;>GEBCO 2019 Grid</a>, made with <a href=&quot;https://www.naturalearthdata.com/&quot;>NaturalEarth</a> by <a href=&quot;https://opendem.info/&quot;>OpenDEM</a>"
                                    url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
                                />
                            </FeatureGroup>
                        </ControlledLayerItem>
                        */}
                        <ControlledLayerItem group="Atributos" checked={true} name="Batimetria"><FeatureGroup><BathymetryComponent /></FeatureGroup></ControlledLayerItem>
                        <ControlledLayerItem group="Atributos" checked={true} name="Dutos"><FeatureGroup><DuctsComponent /></FeatureGroup></ControlledLayerItem>
                        <ControlledLayerItem group="Atributos" checked={true} name="Dutos Flexíveis"><FeatureGroup><FlexibleDuctsComponent /></FeatureGroup></ControlledLayerItem>
                        <ControlledLayerItem group="Atributos" checked={true} name="Gasodutos"><FeatureGroup><PipelineComponent /></FeatureGroup></ControlledLayerItem>
                        <ControlledLayerItem group="Atributos" checked={true} name="Fibra Óptica"><FeatureGroup><FiberOpticsComponent /></FeatureGroup></ControlledLayerItem>
                        <ControlledLayerItem group="Atributos" checked={true} name="Plataformas"><FeatureGroup><PlatformsComponent /></FeatureGroup></ControlledLayerItem>
                        <ControlledLayerItem group="Atributos" checked={this.state.mapOption != 'path'} name="Observatórios"><FeatureGroup><MooringLinesComponent /></FeatureGroup></ControlledLayerItem>
                        <ControlledLayerItem group="Atributos" checked={this.state.mapOption != 'path'} name="Linhas de Fundeio"><FeatureGroup><SubseaObservatoryComponent /></FeatureGroup></ControlledLayerItem>
                        <ControlledLayerItem group="Atributos" checked={true} name="Baía de Santos"><FeatureGroup><BayComponent /></FeatureGroup></ControlledLayerItem>
                        <ControlledLayerItem group="Atributos" checked={true} name="AGBS"><FeatureGroup><AGBSComponent /></FeatureGroup></ControlledLayerItem>
                    </LayersControl2>
                    <MapIndication/>
                </SimmaoMapComponent>
            </div>
        );
    }
}


class TwistPanel extends Reflux.Component {
    constructor(props){
        super(props);
    }
    render(){
        return (
            <div>
                <Header />
                <PanelContent  />
                <PlotModal />
            </div>
        );
    }
}
export {TwistPanel, LoadingComponent}
