import React from 'react';
import Reflux from 'reflux';
import UUID from 'uuid';
import {Link } from "react-router-dom";
import {Image,
    Nav,
    Navbar,
    NavItem,
    Dropdown,
    MenuItem
} from 'react-bootstrap';
import API from '../../api.js';

import styles from './Header.css';

import {
    Modal, Button
} from 'react-bootstrap';
import {AuthStore, MenuStore} from '../../store.js';
import {AuthActions, MenuActions} from '../../actions.js';
import {decryptData, validate} from '../../crypto.js'


import {
    FormGroup,
    ControlLabel,
    FormControl} from 'react-bootstrap';

import petrobrasLogo from './img/petrobras_logo.png';
import ibamaLogo from './img/ibama_logo.png';
import ipqmLogo from './img/ipqm_logo.png';

import {termsOfUse, privacyPolicy} from '../register/terms'

const terms = 'Estes dados ostensivos podem ser apenas utilizados para finalidades científicas. É proibida a utilização para quaisquer aplicações comerciais ou de cunho empresarial. O uso inadquado sujeitará o usuário e sua empresa ou instituição às ações jurídicas cabíveis.';
const terms_eng = 'This data is for scientific use only. Its utilization is forbidden for any comercial or business ends. The inadequate usage of this data may result in legal action againt the resposible company, university, individuals and/or instituitions.'
class Logo extends Reflux.Component {
    render(){
        return <Navbar.Brand className={styles.titleContainer}>
            <a className={styles.title} href="/">
                SIMMAO
                {COMPILE_ENV !== 'prod' &&
                    <p className={styles.beta}>&beta;</p>
                }
            </a>
            <div className={styles.bottomBorder}></div>
            <a className={styles.subtitle} href="/">Sistema de Medições e Modelagem Acústica Oceânica</a>
        </Navbar.Brand>;
    }
}

class RequestDataForm extends Reflux.Component{
    constructor(props){
        super(props);
        this.stores = [AuthStore, MenuStore];
    }
    onHandleChange(e){
        const value = e.target.value;
        MenuActions.handleRequestTextareaChange(value);
    }

    render(){
        return (
            <form>
                <FormGroup controlId="request-form-data">
                    <FormControl
                        type="textarea" value={this.state.requestTextareaValue}
                        componentClass="textarea" rows={10}
                        onChange={this.onHandleChange.bind(this)}/>
                </FormGroup>
            </form>
        );
    }
}

class TermsModal extends Reflux.Component{
    constructor(props){
        super(props);
    }

    handleClick(){
        this.props.showModalCallback(false);
    }

    render(){
        return (
            <div className="static-modal">
                <Modal show={this.props.show} >
                    <Modal.Header>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={styles.termsAndConditionsContainer}>
                            {this.props.terms}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleClick.bind(this)}>Fechar</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

class RequestDataModal extends Reflux.Component{
    constructor(props){
        super(props);
        this.store = MenuStore;
        this.state = {showResponse: false}
    }

    requestData(){
        MenuActions.requestData(decryptData(localStorage.token));
        MenuActions.hideRequestFormModal();
        this.setState({showResponse: true})
    }

    closeResponse(){
        this.setState({showResponse: false});
    }

    render(){
        return (
            <div className="static-modal">
                <Modal show={this.state.showRequestFormModal}>
                    <Modal.Header>
                        <Modal.Title>{'Solicitar Dados | Request Data'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={styles.termsContainer}>
                            {'Prezado Usuário,'}<br/>{terms}
                        </div>
                        <ControlLabel>
                            Por favor informe no campo abaixo:<br/>
                                - Quais são os dados de interesse e qual o período de tempo<br/>
                                - Motivo para requisição dos dados
                        </ControlLabel>
                        <br></br>
                        <br></br>
                        <div className={styles.termsContainer}>
                            {'Dear User,'}<br/>{terms_eng}
                        </div>
                        <ControlLabel>
                            Please desrcibe in the form below:<br/>
                                - The desired data and time period<br/>
                                - The reason for this request
                        </ControlLabel>
                        <br></br>
                        <br></br>
                        <RequestDataForm />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={(e)=>{MenuActions.hideRequestFormModal();}}>{'Fechar | Close'}</Button>
                        <Button bsStyle="primary" onClick={this.requestData.bind(this)}>{'Enviar | Send'}</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showResponse}>
                    <Modal.Body>
                        {this.state.responseStatus == 201 ? 'Seu pedido será avaliado em breve. Obrigado!' : 'Parece que algo deu errado! Por favor tente reenviar seu pedido.'}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeResponse.bind(this)}>Fechar</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

class PrivateNav extends Reflux.Component {
    constructor(props){
        super(props);
        this.stores = [AuthStore, MenuStore];
        this.state = {validate: false}
    }

    logout(){
        AuthActions.logout();
    }

    handleTerms(){
        this.props.termsCallback('terms')
        this.props.showModalCallback(true);
    }

    handlePolicy(){
        this.props.termsCallback('policy')
        this.props.showModalCallback(true);
    }

    componentWillMount(){
        this.setState({validate: validate(decryptData(localStorage.token))})
    }

    render(){
        if (!this.state.loggedin && !this.state.validate){
            return null;
        }
        return (
            <Navbar collapseOnSelect  fluid className={styles.private_nav}>
                <Navbar.Collapse>
                    <Nav>
                        <NavItem componentClass='span' eventKey={3}> <Link to="/panel"> {'Instrumentação Móvel | Mobile Equipment'} </Link> </NavItem>
                        <NavItem componentClass='span' eventKey={2}> <Link to="/list"> {'Instrumentação Fixa | Fixed Equipment'} </Link> </NavItem>
                        <NavItem componentClass='span' eventKey={1}> <Link to="/models"> {'Modelagem de Ruídos | Noise Modelling'} </Link> </NavItem>
                        <NavItem componentClass="span" eventKey={4} onClick={(e=>MenuActions.showRequestFormModal())}><a role="button" href="#">{'Requisitar Dados | Request Data'} </a></NavItem>
                    </Nav>
                    <Nav pullRight style={{marginLeft: 'auto'}}>
                        <Dropdown id="dropdown-custom-1" className={styles.dropdown} >
                            <Dropdown.Toggle className={styles.dropdownTitle}>
                            <i className="fas fa-question-circle"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu  className={styles.dropdownMenu}>
                                <MenuItem eventKey="1" className={styles.terms} onSelect={this.handleTerms.bind(this)}>Termos de Uso</MenuItem>
                                <MenuItem eventKey="1" className={styles.terms} onSelect={this.handlePolicy.bind(this)}>Política de Privacidade</MenuItem>
                                <MenuItem eventKey="3" href="/docs/" className={styles.terms}>Manual de uso</MenuItem>
                                <MenuItem componentClass="Span" className={styles.version} style={{marginTop: 5}}>Versão Front-end:</MenuItem>
                                <MenuItem componentClass="Span" className={styles.version} style={{marginTop: 0}}>{VERSION !== undefined ? VERSION : 'Unknown'}</MenuItem>
                                <MenuItem componentClass="Span" className={styles.version} style={{marginTop: 5}}>Versão Back-end:</MenuItem>
                                <MenuItem componentClass="Span" className={styles.version} style={{marginTop: 0}}>{(new API()).get_api_version().responseJSON["version"].toString()}</MenuItem>
                                <MenuItem componentClass="Span" className={styles.version} style={{marginTop: 5}}>Horários Padrão UTC</MenuItem>
                            </Dropdown.Menu>

                        </Dropdown>
                        <Dropdown id="dropdown-custom-1" className={styles.dropdown} >
                            <Dropdown.Toggle className={styles.dropdownTitle}>
                            <i className="fas fa-user"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className={styles.dropdownMenu}>
                                <MenuItem eventKey="1" onSelect={(e)=>this.logout()} className={styles.logout}> {'Logout'}</MenuItem>
                            </Dropdown.Menu>

                        </Dropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default class Header extends Reflux.Component{

    constructor(props){
        super(props);
        this.twist_style = {
            logo :'./img/logo_verde.png'
        };
        this.state = {
            show: false,
            terms: null
        }
    }

    showModalCallback(show){
        this.setState({show: show})
    }

    termsCallback(terms){
        this.setState({terms: terms})
    }

    render(){
        return (
            <span>
                <Navbar collapseOnSelect fluid>
                    <Navbar.Header>
                        <Logo logo_source={this.twist_style.logo}/>
                    </Navbar.Header>
                    <Navbar.Collapse className={styles.formation}>
                        <Navbar.Text pullRight>Empreendedor: <Image src={petrobrasLogo} /></Navbar.Text>
                        <Navbar.Text pullRight>Orgão Licenciador:  <Image src={ibamaLogo} /></Navbar.Text>
                        <Navbar.Text pullRight>Executor: <Image src={ipqmLogo}/> </Navbar.Text>
                    </Navbar.Collapse>
                </Navbar>
                <PrivateNav showModalCallback={this.showModalCallback.bind(this)} termsCallback={this.termsCallback.bind(this)}/>
                <RequestDataModal/>
                <TermsModal
                    show={this.state.show}
                    showModalCallback={this.showModalCallback.bind(this)}
                    title={this.state.terms == "terms" ? "Termos de Uso" : "Política de Privacidade"}
                    terms={this.state.terms == "terms" ? termsOfUse : privacyPolicy}
                />
            </span>
        );
    }
}
