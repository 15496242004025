import React from 'react';
import Reflux from 'reflux';
import { Redirect } from 'react-router-dom'

import { Panel,
    Col,
    Row,
    FormGroup,
    Button as BsButton,
    FormControl,
    InputGroup} from 'react-bootstrap';

import Header from '../twist_header/Header';
import styles from './Register.css';
import {AuthStore} from '../../store.js';
import {AuthActions} from '../../actions.js';
import {decryptData, validate} from '../../crypto.js'
import {termsOfUse, privacyPolicy} from './terms'

import {Button, Icon} from 'antd'

import {ErrorMessage} from '../login/Login'

class TermsAndConditionsModal extends Reflux.Component{
    constructor(props){
        super(props);
        this.state = {disabled: true}
    }
    handleScroll(e){
        const bottom = e.target.scrollHeight - e.target.scrollTop;
        if (Math.abs(bottom - e.target.clientHeight) < 5){
            this.setState({disabled: false});
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        try {
            AuthActions.registration(
                this.props.email,
                this.props.username,
                this.props.password1,
                this.props.password2
            );
            this.props.showTermsCallback(false);
        } catch (e) {
            console.log('Password reset error')
        }
    }

    handleRefuse(){
        this.props.loadingCallback(false);
        this.props.showTermsCallback(false);
    }

    render(){
        return(
            <div className={styles.termsModalContainer}>
                <div className={styles.termsModal}>
                    <h3>Termos de Uso e Política de Privacidade</h3>
                    <hr></hr>
                    <div className={styles.termsContainer} id="termsContainer" onScroll={this.handleScroll.bind(this)}>
                        <h4>TERMOS DE USO</h4>
                        {termsOfUse}
                        <h4>POLÍTICA DE PRIVACIDADE</h4>
                        {privacyPolicy}
                    </div>
                    <hr></hr>
                    <a className={styles.termsRefuse} onClick={this.handleRefuse.bind(this)}>Recusar</a>
                    <BsButton block bsSize="large" disabled={this.state.disabled} onClick={this.handleSubmit.bind(this)} className={styles.termsButton}> Aceitar </BsButton>
                </div>
            </div>
        );
    }


}

export default class Register extends Reflux.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.store = AuthStore;
        this.state = {
            email: '',
            username: '',
            password1: '',
            password2: '',
            show_terms: false,
            loading: false
        };
    }
    validateForm() {
        return this.state.email.length > 0 && this.state.password1.length > 0 && this.state.password2.length > 0 && this.state.username.length > 0;
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({show_terms: true, loading: true});
    }

    showTermsCallback(show){
        this.setState({show_terms: show});
    }

    loadingCallback(show){
        this.setState({loading: show});
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleLogin() {
        this.props.history.push('/');
    }


    render(){
        if (this.state.loggedin === true || validate(decryptData(localStorage.token))){
            return <Redirect to='/panel' />
        }

        if(this.state.registration_success){
            return (
                <div>
                    <Header />
                    <Row>
                        <Col sm={6} smOffset={6} md={4} mdOffset={4} className={styles.loginForm}>
                            <Panel bsStyle="default" className={styles.panel}>
                                <Panel.Heading  className={styles.headerForm}>
                                    <Panel.Title>
                                    <div className={styles.headerIcon}><Icon type="mail"/></div>
                                        <h3>Verificação de e-mail</h3>
                                    </Panel.Title>
                                </Panel.Heading>
                                <Panel.Body style={{textAlign: 'center'}}>
                                    <p>Um e-mail de verificação foi enviado para sua conta.</p>
                                    <p>Siga os passos indicados para finalizar seu cadastro.</p>
                                </Panel.Body>
                            </Panel>
                            <div className={styles.link}>
                                <span>{'Voltar para página'}</span>
                            <a onClick={this.handleLogin}> {' Login'}</a>
                        </div>
                        </Col>
                    </Row>
                </div>
            );
        }

        return (
            <div className={this.state.show_terms ? styles.showTerms : ''}>
                <Header />
                <Row>
                    <Col sm={6} smOffset={6} md={4} mdOffset={4} className={styles.loginForm}>
                        <Panel bsStyle="default" className={styles.panel}>
                            <Panel.Heading  className={styles.headerForm}>
                                <Panel.Title>
                                    <div className={styles.headerIcon}><Icon type="user-add"/></div>
                                    <h3>{'Cadastro | Register'}</h3>
                                </Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <ErrorMessage/>
                                <form onSubmit={this.handleSubmit}>
                                    <FormGroup controlId="email" bsSize="large">
                                        <InputGroup>
                                            <InputGroup.Addon style={{backgroundColor: 'white'}}>
                                                <Icon type="mail" />
                                            </InputGroup.Addon>
                                            <FormControl
                                                autoFocus
                                                type="email"
                                                placeholder={"E-mail"}
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup controlId="username" bsSize="large">
                                        <InputGroup>
                                            <InputGroup.Addon style={{backgroundColor: 'white'}}>
                                                <Icon type="user" />
                                            </InputGroup.Addon>
                                            <FormControl
                                                type="text"
                                                placeholder={"Usuário | Username"}
                                                value={this.state.username}
                                                onChange={this.handleChange}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup controlId="password1" bsSize="large">
                                        <InputGroup>
                                            <InputGroup.Addon style={{backgroundColor: 'white'}}>
                                                <Icon type="lock" />
                                            </InputGroup.Addon>
                                            <FormControl
                                                placeholder={"Senha | Password"}
                                                value={this.state.password1}
                                                onChange={this.handleChange}
                                                type="password"
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup controlId="password2" bsSize="large">
                                        <InputGroup>
                                            <InputGroup.Addon style={{backgroundColor: 'white'}}>
                                                <Icon type="lock" />
                                            </InputGroup.Addon>
                                            <FormControl
                                                placeholder={"Confirmar Senha | Confirm Password"}
                                                value={this.state.password2}
                                                onChange={this.handleChange}
                                                type="password"
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <Button type="primary" className={styles.submitButton + ' ' + (!this.validateForm() ? styles.disabled : '') } loading={this.state.loading} htmlType={'submit'}> {'Confirmar | Confirm'} </Button>

                                </form>

                            </Panel.Body>
                        </Panel>
                        <div className={styles.link}>
                            <span>{'Já possui conta?'}</span>
                            <a onClick={this.handleLogin}> {'Faça login aqui'}</a>
                            <br></br>
                            <span>{'Already have an account?'}</span>
                            <a onClick={this.handleLogin}> {'Login here'}</a>
                        </div>
                    </Col>
                </Row>
                {this.state.show_terms == true &&
                    <TermsAndConditionsModal
                        username={this.state.username}
                        email={this.state.email}
                        password1={this.state.password1}
                        password2={this.state.password2}
                        showTermsCallback={this.showTermsCallback.bind(this)}
                        loadingCallback={this.loadingCallback.bind(this)}/>
                }
            </div>
        );
    }
}
