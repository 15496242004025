import React from 'react';
import Reflux from 'reflux';
import { Switch, Route, Redirect } from 'react-router-dom';
import {AuthStore} from '../store';
import {validate, decryptData} from '../crypto';

// Views
import {TwistPanel} from './panel/Panel';
import EquipmentList from './panel/List';
import NoiseModel from './panel/Models';
import {Login} from './login/Login';
import Verify from './verify/Verify';
import Register from './register/Register';
import Reset from './password_reset/Reset';


function PrivateRoute({ component: Component, authed, ...rest}){
    return (
        <Route
            {...rest}
            render={props => authed === true
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/', state: { from: props.location }}} />}
        />);
}

export default class Main extends Reflux.Component {
    constructor(props){
        super(props);
        this.store = AuthStore;
    }
    render(){
        var validation = validate(decryptData(localStorage.token) || false)
        return (
            <main>
                <Switch>
                    <Route exact path='/' component={Login}/>
                    <Route exact path='/registration' component={Register}/>
                    <Route path='/verify-email/:key' component={Verify}/>
                    <Route path='/password-reset/:uid?/:token?' component={Reset}/>
                    <PrivateRoute authed={this.state.loggedin  || validation} path='/panel' component={TwistPanel}/>
                    <PrivateRoute authed={this.state.loggedin  || validation}  path='/list' component={EquipmentList}/>
                    <PrivateRoute authed={this.state.loggedin  || validation}  path='/models' component={NoiseModel}/>
                </Switch>
            </main>
        );
    }
}
