import { withLeaflet, MapLayer } from "react-leaflet";
import L from 'leaflet'
import 'leaflet-velocity'

class WeatherLayer extends MapLayer {
    updateLeafletElement(fromProps, toProps) {
        if(toProps.data && toProps.data !== fromProps.data){
            this.leafletElement.options.data = toProps.data;
            if(this.leafletElement._windy){
                this.leafletElement.setData(toProps.data);
            }
        }
    }

    createLeafletElement(props) {
        this.layer = L.velocityLayer({
            displayValues: props.displayValues,
            displayOptions: {
                velocityType: ' ' || props.velocityType,
                displayPosition: 'bottomleft' || props.displayPosition,
                displayEmptyString: 'No wind data' || props.displayEmptyString,
                directionString: "Direção do vento",    
                speedString: "Velocidade do vento"
            },
            data: props.data,
            maxVelocity: props.maxVelocity,
            minVelocity: props.minVelocity,
            colorScale: props.colorScale,
            lineWidth: null || props.lineWidth
        }); 
        return this.layer;
    }
  }

  export default withLeaflet(WeatherLayer)