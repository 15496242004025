import $ from 'jquery';
import {decryptData} from './crypto';

var Config = require('Config');

export default class API {
    constructor(){
        this.a = 'a';
        this.path_data = {};
    }

    get_api_version(){
        const url = `${Config.REST_API}api/version/`;
        return $.ajax({
            url: url,
            beforeSend : function( xhr ) {
                xhr.setRequestHeader( 'Authorization', `Token ${decryptData(localStorage.token)}`);
            },
            async:false,
            contentType: 'application/json',
            crossDomain: true,
            type: 'POST'});
    }

    get_path_data(filters){
        const paths_url = `${Config.REST_API}api/path/`;
        filters = Object.assign({}, filters, {mobile: true});
        let filters_copy = _.cloneDeep(filters);
        delete filters_copy['min_depth'];
        delete filters_copy['max_depth'];
        return $.ajax({
            url: paths_url,
            beforeSend : function( xhr ) {
                xhr.setRequestHeader( 'Authorization', `Token ${decryptData(localStorage.token)}`);
            },
            contentType: 'application/json',
            data: JSON.stringify(filters_copy),
            async:false,
            crossDomain: true,
            type: 'POST'});
    }

    get_user_data(token){
        const url = `${Config.REST_API}rest-auth/user/`;
        return $.ajax({
            url: url,
            beforeSend : function( xhr ) {
                xhr.setRequestHeader( 'Authorization', `Token ${token}`);
            },
            async:false,
            crossDomain: true,
            type: 'GET'});

    }

    verify_email(key){
        const url = `${Config.REST_API}rest-auth/registration/verify-email/`;
        const data = {key: key}
        return $.ajax({
            url: url,
            async:false,
            contentType: 'application/json',
            data:  JSON.stringify(data),
            crossDomain: true,
            type: 'POST'});

    }

    get_spectrumlocation(lat, lng, filters){
        const url = `${Config.REST_API}api/spectrumlocation/`;
        filters = Object.assign({}, filters, { lat : lat, lng : lng } );
        return $.ajax({
            url: url,
            beforeSend : function( xhr ) {
                xhr.setRequestHeader( 'Authorization', `Token ${decryptData(localStorage.token)}`);
            },
            contentType: 'application/json',
            data: JSON.stringify(filters),
            async:false,
            crossDomain: true,
            type: 'POST'});

    }

    get_spectrumobservatory(observatory_id, target_datetime, filters){
        const url = `${Config.REST_API}api/spectrumobservatory/`;
        filters = Object.assign({}, filters, { observatory_id : observatory_id, target_datetime : target_datetime });
        return $.ajax({
            url: url,
            beforeSend : function( xhr ) {
                xhr.setRequestHeader( 'Authorization', `Token ${decryptData(localStorage.token)}`);
            },
            contentType: 'application/json',
            data: JSON.stringify(filters),
            async:false,
            crossDomain: true,
            type: 'POST'});

    }

    get_spectrumgrid(lat, lng, filters){
        const url = `${Config.REST_API}api/spectrumgrid/`;
        filters = Object.assign({}, filters, { lat : lat, lng : lng } );
        return $.ajax({
            url: url,
            beforeSend : function( xhr ) {
                xhr.setRequestHeader( 'Authorization', `Token ${decryptData(localStorage.token)}`);
            },
            contentType: 'application/json',
            data: JSON.stringify(filters),
            async:false,
            crossDomain: true,
            type: 'POST'});

    }

    get_soundexposure24hgrid(lat, lng, filters){
        const url = `${Config.REST_API}api/soundexposure24hgrid/`;
        filters = Object.assign({}, filters, { lat : lat, lng : lng } );
        return $.ajax({
            url: url,
            beforeSend : function( xhr ) {
                xhr.setRequestHeader( 'Authorization', `Token ${decryptData(localStorage.token)}`);
            },
            contentType: 'application/json',
            data: JSON.stringify(filters),
            async:false,
            crossDomain: true,
            type: 'POST'});

    }

    refine_date(range, filters, url){
        if(range !== null && range !== undefined){
            filters['from_datetime'] = range[0].split('T')[0] + 'T00:00:00';
            filters['to_datetime'] = range[1].split('T')[0] + 'T23:59:59';
        }
        return $.ajax({
            url: url,
            beforeSend : function( xhr ) {
                xhr.setRequestHeader( 'Authorization', `Token ${decryptData(localStorage.token)}`);
            },
            contentType: 'application/json',
            data: JSON.stringify(filters),
            async:false,
            crossDomain: true,
            type: 'POST'});
    }

    get_spectrummatrix(observatory_id, depth, spl, range){
        const url = `${Config.REST_API}api/spectrumdate/band/`;
        const filters = {
            observatory_id: observatory_id,
            mobile:false,
            frequency: spl,
            ...(depth != 0 && {min_depth: depth, max_depth: depth})
        } ;

        return this.refine_date(range, filters, url);
    }

    get_spectrummatrixspl(observatory_id, depth, spl, range){
        const url = `${Config.REST_API}api/spectrumdate/${spl}/`;
        const filters = {
            observatory_id: observatory_id,
            mobile:false,
            ...(depth != 0 && {min_depth: depth, max_depth: depth})
        } ;

        return this.refine_date(range, filters, url);

    }

    get_series(observatory_id, depth, range, url){
        let filters = {
            observatory_id: observatory_id,
            mobile: false,
            ...(depth != 0 && {min_depth: depth, max_depth: depth})
        } ;
        if(range !== null && range !== undefined){
            filters['from_datetime'] = range[0];
            filters['to_datetime'] = range[1];
        }
        return $.ajax({
            url: url,
            beforeSend : function( xhr ) {
                xhr.setRequestHeader( 'Authorization', `Token ${decryptData(localStorage.token)}`);
            },
            contentType: 'application/json',
            data: JSON.stringify(filters),
            async:false,
            crossDomain: true,
            type: 'POST'});

    }

    get_spectrumseries(observatory_id, depth, range){
        const url = `${Config.REST_API}api/spectrumseries/`;
        return this.get_series(observatory_id, depth, range, url)
    }

    get_sel24hseries(observatory_id, depths, range){
        const url = `${Config.REST_API}api/soundexposure24hseries/`;
        let data = []
        this.get_series(observatory_id, 0, range, url).done(res =>{
            if(res){
                res['data']['name'] = 'Média'
                data.push(res)
            }
        })
        for(let i = 0; i< depths.length ; i++){
            this.get_series(observatory_id, depths[i], range, url).done(res =>{
                if(res){
                    res['data']['name'] = depths[i]+'m'
                    data.push(res)
                }
            })
        }
        return data
    }

    get_observatories(){
        const url = `${Config.REST_API}api/observatory/`;
        const return_data = $.ajax({
            url: url,
            beforeSend : function( xhr ) {
                xhr.setRequestHeader( 'Authorization', `Token ${decryptData(localStorage.token)}`);
            },
            contentType: 'application/json',
            async:false,
            crossDomain: true,
            type: 'POST'});
        return return_data;
    }

    get_equipments(exclude_mobile=false){
        const url = `${Config.REST_API}api/equipment/`;
        let data = {
            mobile: !exclude_mobile
        };
        const return_data = $.ajax({
            url: url,
            beforeSend : function( xhr ) {
                xhr.setRequestHeader( 'Authorization', `Token ${decryptData(localStorage.token)}`);
            },
            contentType: 'application/json',
            async:false,
            data:JSON.stringify(data),
            crossDomain: true,
            type: 'POST'});
        return return_data;
    }

    get_raster(endpoint, filters){
        const url = `${Config.REST_API}api/raster/${endpoint}/`;
        const return_data = $.ajax({
            url: url,
            beforeSend : function( xhr ) {
                xhr.setRequestHeader( 'Authorization', `Token ${decryptData(localStorage.token)}`);
            },
            contentType: 'application/json',
            data: JSON.stringify(filters),
            async:false,
            crossDomain: true,
            type: 'POST'});
        return return_data;

    }

    get_noisemap(){
        const url = `${Config.REST_API}api/noisemap/`;
        const return_data = $.ajax({
            url: url,
            beforeSend : function( xhr ) {
                xhr.setRequestHeader( 'Authorization', `Token ${decryptData(localStorage.token)}`);
            },
            contentType: 'application/json',
            async:false,
            crossDomain: true,
            type: 'POST'});
        return return_data;
    }

    get_noisemap_data(id, spl){
        const url = `${Config.REST_API}api/raster/noisemap/${spl}/`;
        const data = {model_id: id}
        const return_data = $.ajax({
            url: url,
            beforeSend : function( xhr ) {
                xhr.setRequestHeader( 'Authorization', `Token ${decryptData(localStorage.token)}`);
            },
            contentType: 'application/json',
            data: JSON.stringify(data),
            async:false,
            crossDomain: true,
            type: 'POST'});
        return return_data;
    }

    request_data(text, token){
        const url = `${Config.REST_API}api/datarequest/`;
        let data = {
            request: text
        };
        return $.ajax({
            url: url,
            beforeSend : function( xhr ) {
                xhr.setRequestHeader( 'Authorization', `Token ${decryptData(localStorage.token)}`);
            },
            async:false,
            data: JSON.stringify(data),
            contentType: 'application/json',
            crossDomain: true,
            type: 'POST'});

    }

    get_weather(timestamp, type){
        const url = type == 'wind' ? `${Config.REST_API}api/weather/wind` : `${Config.REST_API}api/weather/wave`;
        let data = 'timestamp='+timestamp;

        return $.ajax({
            url: url,
            beforeSend : function( xhr ) {
                xhr.setRequestHeader( 'Authorization', `Token ${decryptData(localStorage.token)}`);
            },
            async:true,
            data: data,
            crossDomain: true,
            type: 'GET'});

    }
}
