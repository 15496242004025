import React, { Children, cloneElement, Component } from "react";
import ReactDOM from "react-dom";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import Checkbox from "@material-ui/core/Checkbox";
import { Control, DomUtil, DomEvent } from "leaflet";
import { withLeaflet, MapControl, LeafletProvider } from "react-leaflet";
import { IconButton } from "@material-ui/core";
import LayerIcon from "@material-ui/icons/Layers";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListIcon from "@material-ui/icons/List";
import CloudIcon from "@material-ui/icons/Cloud";
import CloseIcon from "@material-ui/icons/Close";
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import "!style-loader!css-loader!antd/dist/antd.css";
import moment from 'moment';
import {WeatherActions} from '../../actions';
import { LoadingComponent } from './Panel'

const icons = {
  Clima: <CloudIcon />,
  Atributos: <ListIcon />,
};

export class ControlledLayer extends Component {
  componentDidUpdate({ checked }) {
    if (this.props.leaflet.map == null) {
      return;
    }
    // Handle dynamically (un)checking the layer => adding/removing from the map
    if (this.props.checked === true && (checked == null || checked === false)) {
      this.props.leaflet.map.addLayer(this.layer);
    } else if (
      checked === true &&
      (this.props.checked == null || this.props.checked === false)
    ) {
      this.props.leaflet.map.removeLayer(this.layer);
    }
  }

  componentWillUnmount() {
    this.props.leaflet.map.removeLayer(this.layer);
  }

  addLayer() {
    throw new Error("Must be implemented in extending class");
  }

  removeLayer(layer) {
    this.props.removeLayer(layer);
  }

  render() {
    const { children } = this.props;
    return children ? (
      <LeafletProvider value={this.contextValue}>{children}</LeafletProvider>
    ) : null;
  }
}

export class ControlledLayerItem extends ControlledLayer {
  constructor(props) {
    super(props);
    this.contextValue = {
      ...props.leaflet,
      layerContainer: {
        addLayer: this.addLayer.bind(this),
        removeLayer: this.removeLayer.bind(this)
      }
    };
  }

  addLayer(layer) {
    this.layer = layer; // Keep layer reference to handle dynamic changes of props
    const { addGroupedLayer, checked, name, group, disabled } = this.props;
    this.props.addGroupedLayer(layer, name, checked, group, disabled);
  };
}

class LayerControl extends MapControl {
  constructor(props, context) {
    super(props);
    this.controlProps = {
      addGroupedLayer: this.addGroupedLayer.bind(this),
      removeLayer: this.removeLayer.bind(this),
      leaflet: props.leaflet
    };
    this._layers = {};

    this.state = {
      menuOpen: false,
      layers: {},
      menus: [],
      weather_date: null,
      loading: false
    };
  }

  openMenu(){
    this.setState({ menuOpen: true });
  };
  closeMenu(){
    this.setState({ menuOpen: false });
  };

  addGroupedLayer(layer, name, checked, group, disabled) {
    if (checked && this.props.leaflet.map != null) {
      this.props.leaflet.map.addLayer(layer);
    }

    this.setState((prevState, props) => {
      let currentLayers = { ...prevState.layers };
      let currentGroup = currentLayers[group];

      currentGroup = currentGroup
        ? [
            ...currentGroup.filter(x => x.name !== name),
            { layer, name, checked, group, disabled }
          ]
        : [{ layer, name, checked, group, disabled }];
      currentLayers[group] = currentGroup;
      return {
        layers: currentLayers
      };
    });

    // bad implementation

    let currentGroup = this._layers[group];

    currentGroup = currentGroup
      ? [
          ...currentGroup.filter(x => x.name !== name),
          { layer, name, checked, group, disabled }
        ]
      : [{ layer, name, checked, group, disabled }];

    let layers = { ...this._layers };
    layers[group] = currentGroup;

    this._layers = layers;
  };

  removeLayer(layer) {
    if (this.props.leaflet.map != null) {
      this.props.leaflet.map.removeLayer(layer);
    }
  }
  //create and return a leaflet object you want to extend
  createLeafletElement(props) {
    // extend control from leaflet
    const MyControl = Control.extend({
      onAdd: map => {
        this.container = DomUtil.create("div");
        this.map = map;
        DomEvent.disableClickPropagation(this.container);
        DomEvent.disableScrollPropagation(this.container);
        return this.container;
      },
      onRemove: map => {}
    });

    return new MyControl(props);
  }

  updateLeafletElement(fromProps, toProps) {
    super.updateLeafletElement(fromProps, toProps);
  }

  componentDidMount(props) {
    super.componentDidMount();
    this.forceUpdate();
  }

  componentDidUpdate(prevProps){
    if(this.props.children.length != prevProps.children.length){
      return
    }

    
    for(let i = 0; i < this.props.children.length; i++){
      
      let layer = this.props.children[i].props;
      let prev_layer = prevProps.children[i].props;
      
      if(layer.disabled == !prev_layer.disabled ){
        
        let _layers = this.state.layers;
        let group = _layers[layer.group];
        
        for(let j = 0; j < group.length; j++){
          if(group[j].name == layer.name){
            
            _layers[layer.group][j]['disabled'] = layer.disabled;
            
            if(layer.checked && !layer.disabled){
              this.toggleLayer(_layers[layer.group][j]);
              this.setState({loading: false});
            }
          }
        }

        this.setState({
          layers: _layers
        });

      }else if(layer.autoCheck && layer.checked && layer.checked !== prev_layer.checked){
        let _layers = this.state.layers;
        let group = _layers[layer.group];
        
        for(let j = 0; j < group.length; j++){
          
          if(group[j].name == layer.name){
            
            if(!_layers[layer.group][j]['checked']){
              this.toggleLayer(_layers[layer.group][j]);
            }
            this.setState({loading: false});
          }
        }
      }


    }
  }

  toggleLayer(layerInput) {
    const { layer, name, checked, group, disabled } = layerInput;
    let layers = { ...this.state.layers };
    layers[group] = layers[group].map(l => {
      if (l.name === name) {
        l.checked = !l.checked;
        l.checked ? this.props.leaflet.map.addLayer(layer) : this.removeLayer(layer);
      }

      return l;
    });

    this.setState({
      layers
    });
  };

  onCollapseClick(name) {
    const { menus } = this.state;

    menus.includes(name)
      ? this.setState({
          menus: [...this.state.menus.filter(x => x !== name)]
        })
      : this.setState({
          menus: [...menus, name]
        });
  };

  isMenuOpen(name) {
    let open = this.state.menus.includes(name);
    return open;
  };

  disabledDateTime() {
    return {
      disabledHours: () => Array.from(Array(10).keys()).splice(),
      disabledMinutes: () => Array.from(Array(60).keys()).splice(),
      disabledSeconds: () => Array.from(Array(60).keys()).splice(),
    };
  }

  getWeather(){
    this.setState({loading: true});
    WeatherActions.getWeatherData(this.state.weather_date, 'wind');
    WeatherActions.getWeatherData(this.state.weather_date, 'wave');
  }

  render() {
    if (!this.leafletElement || !this.leafletElement.getContainer()) {
      return null;
    }

    let calendarDivStyle = {
      maxWidth: '170px'
    };
    return (
      <React.Fragment>
        {ReactDOM.createPortal(
          <Paper
            onClick={this.openMenu.bind(this)} 
            {...this.props}
          >
            {this.state.menuOpen && (
              <div style={{ padding: 10, maxHeight: 400, overflowY:"auto" }}>
                <IconButton onClick={(e) => {e.stopPropagation(); this.closeMenu(); }} size="small" style={{float: 'right'}}><CloseIcon /></IconButton>
                {Object.keys(this.state.layers).map(g => {
                  return (
                    <React.Fragment key={g}>
                      <ListItem
                        button
                        onClick={(e) => {
                          this.onCollapseClick(`${g}`)
                          e.preventDefault();
                        }}
                        style={{ fontSize: 14 }}
                      >
                        <ListItemIcon>{icons[g]}</ListItemIcon>
                        <ListItemText primary={g} />
                        {this.isMenuOpen(g) ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Typography />
                      <Divider />
                      <Collapse
                        in={this.isMenuOpen(g)}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List>
                          { g == 'Clima' && 
                            <DatePicker
                                  locale={locale}
                                  defaultValue={ this.state.weather_date ? moment(this.state.weather_date) : null }
                                  onChange={(date, dateString) => {this.setState({weather_date: dateString})}}
                                  onOk={ this.getWeather.bind(this) }
                                  disabledDate={(current) => {return (current < moment('2015-11-12') || current > moment().subtract(2, "days").endOf('day'))}}
                                  format="YYYY-MM-DD HH:mm"
                                  showTime={{ defaultValue: moment('00', 'HH'), format: 'HH', hourStep: 6 }} 
                                  disabledTime={this.disabledDateTime}
                                  style={calendarDivStyle} 
                              />
                          }
                          {this.state.layers[g].map(l => {
                            // console.log(l)
                            return (
                              <ListItem key={l.name}>
                                <ListItemIcon>
                                  <Checkbox
                                    onClick={() => this.toggleLayer(l)}
                                    edge="start"
                                    checked={l.checked}
                                    disabled = {l.disabled}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={l.name} />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  );
                })}
              </div>
            )}
            {!this.state.menuOpen && (
              <IconButton>
                <LayerIcon fontSize="large"/>
              </IconButton>
            )}
          </Paper>,
          this.leafletElement.getContainer()
        )}
        {Children.map(this.props.children, child => {
          return child ? cloneElement(child, this.controlProps) : null;
        })}
        {this.state.loading &&
          <LoadingComponent loading={this.state.loading}/>

        }
      </React.Fragment>
    );
  }
}

export default withLeaflet(LayerControl);
