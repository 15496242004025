import React from 'react';
import Reflux from 'reflux';
import _ from 'lodash';


import {GeoJSON, FeatureGroup, LayersControl} from 'react-leaflet';
import Control from 'react-leaflet-control';

import {LoadingComponent} from './Panel'

// import {Button} from 'react-bootstrap';
import {Button} from 'antd';

import Header from '../twist_header/Header';
import styles from './Panel.css';
import SimmaoMapComponent from './Map';
import SelectComponent from './MultiSelect'
import API from '../../api';

import {
    BathymetryComponent, 
    AGBSComponent, 
    DuctsComponent, 
    SubseaObservatoryComponent, 
    MooringLinesComponent, 
    PlatformsComponent,
    PipelineComponent,
    FiberOpticsComponent,
    FlexibleDuctsComponent
} from './PanelMapData'

import LayersControl2, { ControlledLayerItem } from "./LayerControl";

    const metricsOptions = {
         "mean": "Média",
         "5p": "5 Percentil",
         "50p": "50 Percentil",
         "95p": "95 Percentil"
    };
    
    const periodOptions = {
        "001": "Janeiro",
        "002": "Fevereiro",
        "003": "Março",
        "004": "Abril",
        "005": "Maio",
        "006": "Junho",
        "007": "Julho",
        "008": "Agosto",
        "009": "Setembro",
        "010": "Outubro",
        "011": "Novembro",
        "012": "Dezembro",
        "VER": "Verão",
        "PRI": "Primavera",
        "OUT": "Outono",
        "INV": "Inverno"
    }

class FilterButtonComponent extends Reflux.Component {
    constructor(props){
        super(props);
        this.state = {loading: false};
    }

    getId(selections){
        const api = new API();
        let data = null
        this.setState({loading: true});
        setTimeout(function(){
            data = api.get_noisemap().responseJSON;
            let filter = {};
            for(var key in selections){    
                if(key != 'metrics')
                    filter[key] = selections[key];
            }
            let response = _.filter(data, filter);
            if(response.length > 0){
                selections['id'] = response[0].id;
            }else
                this.props.dataCallback(null, null);
            
            api.get_noisemap_data(selections.id, selections.metrics).done(
                function(data){
                    this.props.dataCallback(data, selections);
                }.bind(this)
            );
            this.setState({loading: false});
        }.bind(this), 1000)
    }
    
    handleClick(){
            this.getId(this.props.selection);
    }

    render(){
        return(<div><Button 
            type="primary" 
            className={styles.filterButton + ' ' + (!this.props.enable ? styles.disabled : '') } 
            loading={this.state.loading} 
            onClick={this.handleClick.bind(this)}> 
                {'Aplicar'}  
            </Button>
            {this.state.loading != false &&
                <LoadingComponent/>
            }</div>)
    }
}


class RasterComponent extends Reflux.Component {
    constructor(props, context){
        super(props, context);
        this.state = {'layer': null};
    }
    getUrl(b64img){
        return `data:image/jpeg;base64,${b64img}`;
    }

    get_style(feature){
        return feature.properties.style ;
    }

    render(){
        if(this.props.data != null)
            return(<div>
                <Control position="topleft">
                    <img src={this.getUrl(this.props.data.colorbar) } />
                </Control>
                <GeoJSON
                    key={_.uniqueId('modelData_')}
                    id="raster_vector_overlay"
                    data={this.props.data.geojson}
                    style={this.get_style}
                />
            </div>);
        else return(null);
    }
}


class SideBarComponent extends Reflux.Component {
    constructor (props, context){
        super(props, context);
        this.state = {enableFilterButton: false, selection: null};
    }

    buttonCallback(enable){
        this.setState({enableFilterButton: enable})
    
    }

    selectionCallback(selection){
        this.setState({selection: selection})
    }

    render(){
        return (
            <div id="sidebar-menu" className={styles.sideBarMenuContainer} >
                <FilterButtonComponent 
                    enable={this.state.enableFilterButton} 
                    selection ={this.state.selection}                    
                    dataCallback={this.props.dataCallback.bind(this)}
                    />
                <SelectComponent 
                    buttonCallback={this.buttonCallback.bind(this)} 
                    selectionCallback={this.selectionCallback.bind(this)}
                />
            </div>
        );
    }
}

class ModelWarning extends Reflux.Component{
    render(){
        return(
            <div className={styles.experimentalModel}>
                <h2>Modelos em desenvolvimento</h2>
            </div>
        );
    }
}

class ModelMapIndication extends Reflux.Component{
    constructor(props){
        super(props);
    }

    render(){
        if(!this.props.selection)
            return null
        let { metrics, depth, frequency, year, period } = this.props.selection;
        return(
            <div className={styles.modelMapIndication}>
                Métrica: { metricsOptions[metrics] }<br/>
                Profundidade: { depth+'m' }<br/>
                Frequência: { frequency+'hz' }<br/>
                Ano: { year }<br/>
                Período: { periodOptions[period] }<br/>
            </div>
        )
    }
}


export default class NoiseModel extends Reflux.Component {
    constructor (props, context){
        super(props, context);
        this.state = {data: null, loading: false, last_selection: null};
    }

    dataCallback(data, last_selection){
        this.setState({data: data, last_selection: last_selection})
    }

    render(){

        return (
            <div>
                <Header />
                <div  className={styles.wrapper}>
                    <SideBarComponent 
                        dataCallback={this.dataCallback.bind(this)}
                    />
                    <SimmaoMapComponent small={false}>
                        <RasterComponent data={this.state.data}/>
                        <ModelWarning/>
                        <ModelMapIndication selection={this.state.last_selection}/>
                        
                        <LayersControl2 position="bottomright">
                            <ControlledLayerItem group="Atributos" checked={true} name="Batimetria"><FeatureGroup><BathymetryComponent /></FeatureGroup></ControlledLayerItem>
                            <ControlledLayerItem group="Atributos" checked={true} name="Dutos"><FeatureGroup><DuctsComponent /></FeatureGroup></ControlledLayerItem>
                            <ControlledLayerItem group="Atributos" checked={true} name="Dutos Flexíveis"><FeatureGroup><FlexibleDuctsComponent /></FeatureGroup></ControlledLayerItem>
                            <ControlledLayerItem group="Atributos" checked={true} name="Gasodutos"><FeatureGroup><PipelineComponent /></FeatureGroup></ControlledLayerItem>
                            <ControlledLayerItem group="Atributos" checked={true} name="Fibra Óptica"><FeatureGroup><FiberOpticsComponent /></FeatureGroup></ControlledLayerItem>
                            <ControlledLayerItem group="Atributos" checked={true} name="Plataformas"><FeatureGroup><PlatformsComponent /></FeatureGroup></ControlledLayerItem>
                            <ControlledLayerItem group="Atributos" checked={false} name="Observatórios"><FeatureGroup><MooringLinesComponent /></FeatureGroup></ControlledLayerItem>
                            <ControlledLayerItem group="Atributos" checked={false} name="Linhas de Fundeio"><FeatureGroup><SubseaObservatoryComponent /></FeatureGroup></ControlledLayerItem>
                            <ControlledLayerItem group="Atributos" checked={true} name="Baía de Santos"><FeatureGroup><AGBSComponent /></FeatureGroup></ControlledLayerItem>
                        </LayersControl2>
                    </SimmaoMapComponent>
                </div>
            </div>
        );
    }
}