import React from 'react';
import Reflux from 'reflux';
import Plot from 'react-plotly.js';
import _ from 'lodash';
import fileDownload from 'js-file-download';

import {
    Modal, Button, Tabs, Tab
} from 'react-bootstrap';


import {
    Form,
    FormGroup,
    Radio,
    Col, Row,
    ListGroupItem,
    ListGroup} from 'react-bootstrap';

import {
    GeoJSON
} from 'react-leaflet';

import SimmaoMapComponent from "./Map"

// ICONS
import {
    mooring_line_base64_icon,
    sso_base64_icon
} from './icons';

import {LoadingComponent} from './Panel'
import {SpectralChart, TwelveSpectralChart} from './PlotPathModal'
import {PlotSel24hModal} from './PlotModal'

import {EquipmentListActions, EquipmentPlotActions, MapActions} from '../../actions';
import {EquipmentListStore, EquipmentPlotStore} from '../../store';
import Header from '../twist_header/Header';
import styles from './Panel.css';
const ldf_style = { color: '#CC0000' };
const sso_style = { color: '#CC0000' };

import '!style-loader!css-loader!rc-slider/assets/index.css';
import {Slider, Select, Icon, Table, message} from 'antd';
import dateformat  from 'dateformat';

var Config = require('Config');
var show_sel24h = Config.SHOW_SEL24H != undefined ? Config.SHOW_SEL24H : true;
import {decryptData} from '../../crypto'

function setGeoJson(equipments, _geoJson, type){
    _.map(equipments, (equipment) =>{
        if(equipment.type == type){
            let feature = {}
            feature["type"] = "Feature";
            feature["properties"] = {
                "name" : equipment.name,
                "label" : equipment.label,
                "id" : equipment.id
            }
            feature["geometry"] = equipment.geolocalization;
            _geoJson.features.push(feature);
        }
    });
    return _geoJson;
}

class MooringLinesComponent extends Reflux.Component {
    constructor(props){
        super(props);
        this.state = {}
        this.stores = [EquipmentListStore, EquipmentPlotStore];
        this.state = {
            geoJson : {
                "type": "FeatureCollection",
                "name": "Linhas de Fundeio Instrumentadas",
                "features": []
            }
        }
    }

    componentDidMount(){
        let _geoJson =  this.state.geoJson;
        _geoJson = setGeoJson(this.state.equipments, _geoJson, 'OS');
        this.setState({geoJson: _geoJson});
    }

    pointToLayer(feature, latlng){
        const icon = new L.divIcon({
            className: styles.mooringIcon,
            html: '<div><img src="' + mooring_line_base64_icon + '"/>' + '<div>'+feature.properties.label+'</div></div>'
        });
        const marker =  L.marker(latlng, {icon: icon});
        marker.on('click', function(){
            this.props.equipmentCallback(feature.properties.id);
        }.bind(this));
        return marker;
    }
    render(){
        return <GeoJSON
            key={_.uniqueId('ldf_')}
            id='df'
            data={this.state.geoJson}
            style={ldf_style}
            pointToLayer={this.pointToLayer.bind(this)}
        />;
    }
}


class SubseaObservatoryComponent extends Reflux.Component {
    constructor(props){
        super(props);
        this.state = {}
        this.stores = [EquipmentListStore, EquipmentPlotStore];
        this.state = {
            geoJson : {
                "type": "FeatureCollection",
                "name": "Observatório Submarino",
                "features": []
            }
        }
    }

    componentDidMount(){
        let _geoJson =  this.state.geoJson;
        _geoJson = setGeoJson(this.state.equipments, _geoJson, 'LFI');
        this.setState({geoJson: _geoJson});
    }

    pointToLayer(feature, latlng){
        const icon = new L.divIcon({
            className: styles.ssoIcon,
            html: '<div><img src="' + sso_base64_icon + '"/>' + '<div>'+feature.properties.label+'</div></div>'
        });
        const marker =  L.marker(latlng, {icon: icon});
        marker.on('click', function(){
            this.props.equipmentCallback(feature.properties.id);
        }.bind(this));
        return marker;
    }
    render(){
        return <GeoJSON
            key={_.uniqueId('sso_')}
            id='sso'
            data={this.state.geoJson}
            style={sso_style}
            pointToLayer={this.pointToLayer.bind(this)}
        />;
    }
}

class PlotModal extends Reflux.Component {
    constructor(props){
        super(props)
        this.state = {};
        this.store = EquipmentPlotStore;
        this.columns = [{
                title: 'Modelo',
                dataIndex: 'model',
                key: 'model',
                align: 'center'
            },{
                title: 'N.º de série',
                dataIndex: 'serial_number',
                key: 'serial_number',
                align: 'center'
            },{
                title: 'Campanha',
                dataIndex: 'campaign',
                key: 'campaign',
                align: 'center'
            },{
                title: 'Leitura',
                dataIndex: 'reading',
                key: 'reading',
                align: 'center'
            }, {
                title: 'Profundidade',
                dataIndex: 'depth',
                key: 'depth',
                align: 'center'
            }, {
                title: 'Latitude',
                key: 'lat',
                dataIndex: 'lat',
                align: 'center'
            }, {
                title: 'Longitude',
                key: 'lng',
                dataIndex: 'lng',
                align: 'center'
            }, {
                title: 'Temperatura',
                key: 'temperature',
                dataIndex: 'temperature',
                align: 'center'
          }];
    }


    close(){
        EquipmentPlotActions.closeModal();
    }

    downloadData(){
        fileDownload(JSON.stringify(this.state.plot_data), 'data.json');
    }


    render(){
        if (!this.state.plot_data)
            return null;
        let data = null;
        let spectrum_twelve_data = null;
        data = this.state.plot_data[0]['spectrum'];
        spectrum_twelve_data = this.state.plot_data[0]['spectrum_12'];
        let model = this.state.plot_data[0]['eq_model'];
        let serial_number = this.state.plot_data[0]['eq_serial'];
        let campaign = this.state.plot_data[0]['campaign'];
        let depth = this.state.plot_data[0]['depth'];
        let temperature = this.state.plot_data[0]['temperature'];
        let lat = this.state.plot_data[0]['lat'];
        let lng = this.state.plot_data[0]['long'];
        let reading_dt = new Date(this.state.plot_data[0]['initial_datetime']);
        let final_reading_dt = new Date(this.state.plot_data[0]['final_datetime']);
        reading_dt = new Date(reading_dt.getTime()+ reading_dt.getTimezoneOffset()*60000);
        final_reading_dt = new Date(final_reading_dt.getTime()+ final_reading_dt.getTimezoneOffset()*60000);
        let day = reading_dt.getDate();
        let month = reading_dt.getMonth() + 1;
        let year = reading_dt.getFullYear();

        let  tableData = [{
            key: _.uniqueId('row-'),
            campaign: campaign,
            model: model,
            serial_number: serial_number,
            reading: dateformat(reading_dt, 'dd/mm/yyyy HH:MM:ss') + ' a ' + dateformat(final_reading_dt, 'HH:MM:ss'),
            temperature: temperature == null ? '-' : temperature.toFixed(1)+'ºC',
            depth: depth == null ? '-': depth + 'm',
            lat: lat,
            lng: lng,
          }];
        return (
            <Modal
                show={this.state.showModal}
                bsSize="large"
                aria-labelledby="contained-modal-title-lg"
                key={this.state.modalPlotElement}
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-lg">{'Mensurações - ' + day+'/'+month+'/'+year}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table dataSource={tableData} columns={this.columns} pagination={false} />
                    <Tabs defaultActiveKey={0} id={_.uniqueId("spl_tab-")}>
                        <Tab eventKey={0} key={0} title={'⅓ oitava'}>
                            <SpectralChart
                                element={this.state.equipment}
                                data={data}
                            />
                        </Tab>
                        <Tab eventKey={1} key={1} title={'1⁄12 oitava'} disabled={spectrum_twelve_data.length == 0}>
                            {   spectrum_twelve_data.length &&
                                <TwelveSpectralChart
                                    element={this.state.equipment}
                                    data={spectrum_twelve_data}
                                />

                            }
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.downloadData.bind(this)}>{'Baixar Dados'}</Button>
                    <Button onClick={this.close.bind(this)}>{'Fechar'}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}


class SideBarComponent extends Reflux.Component {
    constructor(props){
        super(props);
        this.state = {}
        this.stores = [EquipmentListStore, EquipmentPlotStore];
    }

    componentDidMount(){
        EquipmentListActions.loadEquipments();
        if(this.props.clickedEquipment != null){
            var equipment  = document.querySelectorAll('[data-observatory-id="'+ this.props.clickedEquipment +'"]');
            if(equipment.length > 0){
                EquipmentPlotActions.setEquipment(this.props.clickedEquipment);
            }
        }else if(this.state.equipment !== null){
                EquipmentPlotActions.setEquipment(null);
        }
    }

    componentDidUpdate(prevProps){
        var equipment = null;
        if(this.props.clickedEquipment !== null && prevProps.clickedEquipment != this.props.clickedEquipment){
            equipment  = document.querySelectorAll('[data-observatory-id="'+ this.props.clickedEquipment +'"]');
            if(equipment[0].dataset.count == 0){
                message.error('Nenhum dado disponível')
            }else if(equipment.length > 0){
                EquipmentPlotActions.setEquipment(this.props.clickedEquipment);
            }
        }else if(this.props.clickedEquipment !== null && prevProps.clickedEquipment == this.props.clickedEquipment){
            equipment  = document.querySelectorAll('[data-observatory-id="'+ this.props.clickedEquipment +'"]');
            if(equipment[0].dataset.count == 0){
                message.error('Nenhum dado disponível')
            }
        }
    }

    onClick(event){
        event.stopPropagation();
        this.props.equipmentCallback(event.target.getAttribute('data-observatory-id'))
    }

    render(){
        const list_items = _.map(this.state.equipments,
            (value, key) =>
                <ListGroupItem
                    key={value.name}
                    data-observatory-id={value.id}
                    onClick={this.onClick.bind(this)}
                    data-type={value.type}
                    data-count={value.count}
                    className={styles.listGroup + ' ' + (this.props.active == value.id ? styles.listActive : '')}>
                    <img className= {(value.type == "OS" ? styles.listGroupIconMooring : styles.listGroupIconSso)}
                        src = {(value.type == "OS" ? mooring_line_base64_icon : sso_base64_icon)}/>
                    <a href='#'
                        data-observatory-id={value.id}
                        data-type={value.type}
                        data-count={value.count}
                        key={'a-' + key}
                        onClick={this.onClick.bind(this)}
                        className={styles.listLink}> {value.name} </a>
                </ListGroupItem>
        );
        if(list_items.length > 0)
            return (
                <div id="sidebar-menu" className={styles.sideBarMenuContainer} >
                <ListGroup className={styles.sideBarListGroup + ' ' + (this.props.small == true ? styles.smallSideBarListGroup : '')}>
                    {list_items}
                </ListGroup>
                </div>
            );

        return(<LoadingComponent/>);

    }
}


class Sel24hComponent extends Reflux.Component{
    constructor(props){
        super(props);
        this.state = {showSel24hModal: false, range: this.props.range};
        this.stores = [EquipmentListStore, EquipmentPlotStore];
        this.buildPlot()
    }

    buildPlot(){
        this.step_series = [];
        if (!this.state.sel24h_series){
            return null
        }

        const colors = ['#636EFA', '#EF553B', '#00CC96', '#AB63FA', '#FFA15A', '#19D3F3', '#FF6692', '#B6E880', '#FF97FF', '#FECB52']
        for (let i=0; i<this.state.sel24h_series.length; i++){
            let cur_series = this.state.sel24h_series[i].data
            let plot_series = {x: [], y:[], name: cur_series.name}
            for (let j=0; j<cur_series.x.length; j++){
                let left_date = new Date(cur_series.x[j])
                let right_date = new Date(cur_series.x[j+1])
                let new_date = new Date(left_date.getTime() + 86400000)

                plot_series.x.push(cur_series.x[j])
                plot_series.y.push(cur_series.y[j])

                plot_series.x.push(new_date.toISOString().split('T')[0])
                plot_series.y.push(cur_series.y[j])

                // insert gap on date breaks larger than 1 day
                if ((right_date - left_date) > 86400000){
                    plot_series.x.push(null);
                    plot_series.y.push(null);
                }
            }

            this.step_series.push({
                x: plot_series.x,
                y: plot_series.y,
                name: plot_series.name,
                legendgroup: plot_series.name,
                mode: 'lines+markers',
                type: 'scatter',
                line: {shape: "hv", color: colors[i%colors.length]}
            })
        }
    }

    onSel24hPlotClick(e){
        let binSearch = function(el, arr){
            let lower=0, upper=arr.length
            while(lower < (upper-1)){
                let mid = Math.floor((lower + (upper-1)) / 2)
                if (el.valueOf() === arr[mid].valueOf()) upper = (mid+1)
                else {
                    if(el.valueOf() < arr[mid].valueOf()) upper = mid
                    else lower = (mid+1)
                }
            }
            return lower
        }

        let modal_data = [];
        let data = this.state.sel24h_series;
        for(let i=0; i<e.points.length; i++){
            let curve = data.find((el) => el.data.name === e.points[i].data.name).data

            // get index of given date considering that the plot
            // has added empty markers at the end of each day
            let dateSearch = new Date(e.points[i].x)
            let x_curve = curve.x.map((el) => new Date(el))
            let index = binSearch(dateSearch, x_curve)
            if(Math.abs(dateSearch - x_curve[index-1]) < Math.abs(dateSearch - x_curve[index])) index = index-1;

            modal_data.push({
                date: curve.x[index] || '-',
                value: curve.y[index] || '-',
                npoints: curve.used_points[index] || 0,
                max_points: curve.max_points[index] || 0,
                depth: curve.name || '-',
                campaign: curve.campaign[index] || '-',
                serial: curve.serial[index] || '-',
                type: curve.type[index] || '-'
            })
        }
        EquipmentPlotActions.setSel24hModalData(modal_data);
    }

    onSelection(eventdata){
        let offset = (new Date(eventdata['xaxis.range[0]'])).getTimezoneOffset() * 60000;
        if(_.isNaN(offset)){
            return
        }
        let from_datetime = new Date(eventdata['xaxis.range[0]']);
        let to_datetime = new Date(eventdata['xaxis.range[1]']);
        from_datetime = (new Date(from_datetime - offset)).toISOString().split('.')[0];
        to_datetime = (new Date(to_datetime - offset)).toISOString().split('.')[0];
        let range = [from_datetime, to_datetime];
        if(this.state.range !== range){
            this.setState({range: range});
        }
        this.props.rangeCallback(range);
    }

    componentDidUpdate(prevProps, prevState){
        if (this.props.range !== prevProps.range ) this.setState({range: this.props.range})
        if (this.state.sel24h_series !== prevState.sel24h_series) this.buildPlot()
    }

    render(){
        return (
            <React.Fragment>
                <Row style={{display: this.props.selection_value == "sel24h" ? 'flex' : 'none', justifyContent: 'center', maxWidth: 'calc(100vw - 350px)'}}>
                    <Col xs={12} md={12} style={{marginRight: 'auto',marginLeft: 'auto',maxWidth: 'calc(100vw - 350px)'}}>
                        <Plot data={this.step_series}
                            key={_.uniqueId('plot-series-')}
                            config={{displayModeBar: false, displaylogo: false}}
                            onClick={this.onSel24hPlotClick.bind(this)}
                            onRelayout={(e) => {this.onSelection(e, 'series')}}
                            style= {{width: "100%", height: "calc(100vh - 340px)"}}
                            useResizeHandler={true}
                            layout={{
                                title: "SEL 24h",
                                xaxis:{
                                    title: "Data",
                                    range:this.state.range
                                },
                                    yaxis:{title: this.props.selection_value == "sel24h"? "SEL (dB ref. 1μPa²⋅s)" : "SEL (dB ref. 1µPa²)"},
                                line_shape: "hv",
                                hovermode: "x",
                                doubleClick: false,
                                autosize: true

                            }}>
                        </Plot>
                        <Button className={styles.plotResetButton} key={_.uniqueId('button-')}
                            onClick={() => {
                                this.setState({range: null});
                                this.props.rangeCallback(null);
                            }}><Icon type="fullscreen-exit" />
                        </Button>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

class MainContentComponent extends Reflux.Component{
    constructor(props){
        super(props);
        this.state = {range: null, loading: false, last_series: null, last_data: null, selection_value: "spl", sel24h_range: null};
        this.stores = [EquipmentListStore, EquipmentPlotStore];
        this.sel24h_permission = JSON.parse(decryptData(localStorage.getItem('permissions'))).includes('view_sel24h');

    }

    componentDidUpdate(prevProps, prevState){
        if((this.state.series == undefined || this.state.data == undefined) && (this.state.last_series !== null && this.state.last_data !== null)){
            this.setState({series: this.state.last_series, data: this.state.last_data})
            message.warning('Zoom máximo atingido');
        }
        if(this.state.equipment == null && prevState.equipment != this.state.equipment)
            return;
        if(this.state.equipment !== undefined && prevState.equipment != this.state.equipment){
            this.props.loadingCallback(true);
            this.props.activeCallback(this.state.equipment);
            setTimeout(function(){
                EquipmentPlotActions.loadData(this.state.spl);
                this.props.leafletCallback();
                this.props.loadingCallback(false);
            }.bind(this), 500);
        }
        if(this.state.depth !== undefined && (prevState.depth != this.state.depth)){
            this.setState({loading: true});
            setTimeout(function(){
                EquipmentPlotActions.loadData(this.state.spl, this.state.range);
                this.setState({loading: false});
            }.bind(this), 500);
        }
        if(this.state.spl !== undefined && prevState.spl != this.state.spl){
            this.setState({loading: true});
            setTimeout(function(){
                EquipmentPlotActions.loadData(this.state.spl, this.state.range);
                this.setState({loading: false});
            }.bind(this), 500);
        }
        if(this.state.spl !== undefined && prevState.range != this.state.range){
            this.setState({loading: true});
            setTimeout(function(){
                EquipmentPlotActions.loadData(this.state.spl, this.state.range)
                this.setState({loading: false});
            }.bind(this), 500);
        }
    }

    plotlyOnClick(e){
        const points = e.points[0].data;
        const pointIndex = e.points[0].pointIndex;

        let target_datetime = points.x[pointIndex].split('.')[0];
        let from_datetime = target_datetime;
        let base_to_datetime = this.state.range ? this.state.range[1] : "9999-12-31T23:59:59";
        let to_datetime = base_to_datetime;
        for (let i=pointIndex+1; (i<points.x.length) && (to_datetime===base_to_datetime); i++){
            if (points.x[i]!==null) {
                to_datetime = new Date(points.x[i]+".000Z");
                to_datetime = new Date(to_datetime - 1000);
                to_datetime = to_datetime.toISOString().split('.')[0];
            }
        }

        EquipmentPlotActions.plotDetail(target_datetime, from_datetime, to_datetime);
    }

    matrixOnClick(e){
        const points = e.points[0].data;
        const pointIndex = e.points[0].pointIndex;

        let target_datetime = points.y[pointIndex[0]] + "T" + points.x[pointIndex[1]] + ":00";
        let from_datetime = target_datetime;
        let to_datetime = new Date(from_datetime+".000Z");
        to_datetime = new Date((to_datetime - 1000) + 600000);
        to_datetime = to_datetime.toISOString().split('.')[0];

        EquipmentPlotActions.plotDetail(target_datetime, from_datetime, to_datetime);
    }

    onSelection(eventdata, type){
        const key = type == 'series' ? _.keys(eventdata)[0] : _.keys(eventdata).sort().reverse()[0];
        const selector = key.split('.')[0];
        const command = (key.split('.')[1] == 'autorange'? 'reset': 'range');
        if(type == 'series' ? selector == 'xaxis': selector == 'yaxis'){
            if(command == 'reset'){
                this.setState({range: null})
            }else{
                let offset = (new Date(eventdata[selector+'.'+command+'[0]'])).getTimezoneOffset() * 60000;
                let from_datetime = new Date(eventdata[selector+'.'+command+'[0]']);
                let to_datetime = new Date(eventdata[selector+'.'+command+'[1]']);
                from_datetime = new Date(from_datetime - offset).toISOString().split('.')[0];
                to_datetime = new Date(to_datetime - offset).toISOString().split('.')[0];
                if(type == 'matrix') {
                    from_datetime = from_datetime.split('T')[0]+ 'T00:00:00';
                    to_datetime = to_datetime.split('T')[0] + 'T23:59:59';
                }
                let range = [from_datetime, to_datetime];
                if(this.state.range !== range){
                    this.setState({range: range});
                }
                if(this.state.series !== undefined){
                    this.setState({last_series: this.state.series, last_data: this.state.data});
                }
            }
        }
    }

    changeDepth(value){
        if(value != 0){
			let clickedEquipment = this.props.clickedEquipment;
			let currentEquipment = _.find(this.state.equipments, obj => {
					return obj.id == clickedEquipment;
			});
            value = currentEquipment.depths.slice()[-(value + 1)]
        }
        EquipmentPlotActions.addDepthFilter(value);
    }

    onChangeSelection(e){
        let radio_value = e.target.value;
        this.setState({selection_value: radio_value});
        if(radio_value == 'spl' && this.state.sel24h_range != this.state.range){
            this.setState({range: this.state.sel24h_range});
            if(this.state.series !== undefined){
                this.setState({last_series: this.state.series, last_data: this.state.data});
            }
        }
    }

    sel24hRangeCallback(range){
        this.setState({sel24h_range: range})
    }

    render(){
        let main = <div> </div>;
        let currentEquipment = null
        this.state.equipments.map(equipment =>{
            if(equipment.id == this.state.equipment)
                currentEquipment = equipment;
        })
        if(!_.isEmpty(this.state.data) && currentEquipment !== null){
            const x_data = [];
            this.state.data.data[0].forEach(function (value, i){
                let date = new Date(null, null, null, null, i*10, null).toTimeString().match(/\d{2}:\d{2}/)[0];
                x_data.push(date);
            });
            let y_data = this.state.data.index;
            y_data.map((date, i) => {
                let new_date  = new Date(date)
                if(new_date.getHours() == 0)
                    // adding only 9 hours since toISOString adds another 3 hours(GMT -0300), resulting 12h
                    y_data[i]  = new Date(new_date.setHours(new_date.getHours() + 9)).toISOString().split('.')[0];
            });
            let y_ticks = this.state.data.index.length;
            const data = [{
                z: this.state.data.data,
                y: y_data,
                x: x_data,
                type:'heatmap',
                colorscale: [
                    [0, 'rgb(0,0,0)'],
                    [0.05, 'rgb(0,0,131)'],
                    [0.1, 'rgb(0,60,170)'],
                    [0.4, 'rgb(5,255,255)'],
                    [0.7, 'rgb(255,255,0)'],
                    [1, 'rgb(255,0,0)']
                ],
                colorbar: {title: "SPL (dB ref. 1µPa²)"},
                zauto: false,
                zmin: 60,
                zmax: 145,
            }];

            // const config = {modeBarButtonsToRemove: ['toImage', 'sendDataToCloud', 'zoom2d', 'pan2d', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toggleSpikelines'], displaylogo: false};
            const config = {displayModeBar: false, displaylogo: false};
            const series = [];
            if (this.state.series){
                this.state.series.map(
                    (cur_series) => {
                        let plot_series = {
                            x: structuredClone(cur_series.x),
                            y: structuredClone(cur_series.y),
                            name: cur_series.name
                        }

                        let max_gap = 0.05 * ((new Date(plot_series.x[plot_series.x.length-1])) - (new Date(plot_series.x[0])))
                        for (let i=0; i<plot_series.x.length-1; i++){
                            if (((new Date(plot_series.x[i+1])) - (new Date(plot_series.x[i]))) > max_gap){
                                plot_series.x.splice(i+1, 0, null)
                                plot_series.y.splice(i+1, 0, null)
                                i++
                            }
                        }

                        series.push({
                            x: plot_series.x,
                            y: plot_series.y,
                            name: plot_series.name,
                            mode: 'lines+markers',
                            type: 'scatter',
                        })
                    }
                )
            }

            let title = null;
            this.state.equipments.map(equipment => {
                if(equipment.id == this.state.equipment)
                    title = equipment.name;
            });
            let marks = {0: "Todas"}
            currentEquipment.depths.slice().map((depth, index) =>{
                marks[-1*(index + 1)] = depth.toString()+'m';
            });

            main =
                <React.Fragment>
                    <div className={styles.lfiTitle}>
                        <h4 style={{marginRight: 23, marginBottom: 0}}>{title}</h4>
                        {show_sel24h && this.sel24h_permission &&
                            <Form inline>
                                <FormGroup style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: 145
                                }}>
                                    <Radio  onChange={this.onChangeSelection.bind(this)}  name="radioGroup" checked={this.state.selection_value == 'spl'} value={'spl'} inline>SPL</Radio><br />
                                    <Radio  onChange={this.onChangeSelection.bind(this)}  name="radioGroup" checked={this.state.selection_value == 'sel24h'} value={'sel24h'} inline>SEL 24h</Radio><br />
                                </FormGroup>
                            </Form>
                        }
                    </div>
                    <div className={styles.mainContentRow}>
                        <Row style={{display: this.state.selection_value == "spl" ? 'flex' : 'none', justifyContent: 'center'}}>
                            <Col xs={12} md={1}>
                                <div style={{ float: 'left', width: 160, height: 350, marginTop: 40, marginLeft: 25 }}>
                                    <p style={{marginBottom: 23}}>Profundidade:</p>
                                    <Slider
                                        included={false}
                                        min={-currentEquipment.depths.length}
                                        max={0}
                                        marks={marks}
                                        onAfterChange={this.changeDepth.bind(this)}
                                        step={1}
                                        tooltipVisible={false}
                                        defaultValue={this.state.depth == 0 ? 0 : -(currentEquipment.depths.slice().reverse().indexOf(this.state.depth) + 1)}
                                        vertical/>
                                </div>
                            </Col>
                            <Col xs={12} md={5} style={{maxWidth: 700}}>
                                <Plot data={series}
                                    key={_.uniqueId('plot-series-')}
                                    onClick={this.plotlyOnClick.bind(this)}
                                    config={config}
                                    onRelayout={(e) => {this.onSelection(e, 'series')}}
                                    layout={{
                                        title: "SPL",
                                        xaxis:{
                                            title: "Data",
                                        },
                                        yaxis:{title: "SPL (dB ref. 1µPa²)"},
                                    }}/>
                            </Col>
                            <Col xs={12} md={5} style={{marginLeft: 60, maxWidth: 700}}>
                                <Select defaultValue={this.state.spl} className={styles.matrixSelect} style={{ width: 120 }}
                                    loading={this.state.loading}
                                    onChange={value => {
                                        this.setState({loading: true})
                                        setTimeout(function(){
                                            EquipmentPlotActions.setSPL(value);
                                        }, 500)
                                    }} >
                                    <Option value="peak">spl_pp</Option>
                                    <Option value="50p">SPL50p</Option>
                                    <Option value="63">63Hz</Option>
                                    <Option value="125">125Hz</Option>
                                    <Option value="500">500Hz</Option>
                                    <Option value="1000">1000Hz</Option>
                                </Select>
                                <Plot data={data}
                                    key={_.uniqueId('plot-matrix-')}
                                    onClick={this.matrixOnClick.bind(this)}
                                    onRelayout={(e) => {this.onSelection(e, 'matrix')}}
                                    layout={ {
                                        xaxis:{
                                            title: "Hora",
                                            nticks: 10,
                                        },
                                        yaxis:{
                                            title: "Data",
                                            tickformat: '%b %Y',
                                            nticks: y_ticks < 10 ? y_ticks : 10,
                                        }
                                    }}
                                    config={config}/>
                                <Button className={styles.plotResetButton} key={_.uniqueId('button-')}
                                    onClick={() => {
                                        this.setState({range: null});
                                        EquipmentPlotActions.loadData(this.state.spl);
                                    }}><Icon type="fullscreen-exit" />
                                </Button>
                            </Col>
                        </Row>
                        {this.state.loading != false &&
                            <LoadingComponent/>
                        }
                    </div>
                    <Sel24hComponent
                        marks={marks}
                        selection_value={this.state.selection_value}
                        currentEquipment={currentEquipment}
                        changeDepth={this.changeDepth.bind(this)}
                        range={this.state.range}
                        rangeCallback={this.sel24hRangeCallback.bind(this)}
                    />
                </React.Fragment>;

        }
        return <div className={styles.MainContainer}>
            {main}
        </div>;
    }
}

class PanelContent extends Reflux.Component{
    constructor(props){
        super(props);
        this.state = {loading: false, active: null, small: false, clickedEquipment: null}
    }

    loadingCallback(loading){
        this.setState({loading: loading});
    }

    activeCallback(id){
        this.setState({active: id});
    }

    leafletCallback(small){
        this.setState({small: true});
    }

    equipmentCallback(id){
        this.setState({clickedEquipment: id});
    }

    render(){
        return(
            <div className={styles.wrapper}>
                <SideBarComponent
                    loadingCallback = {this.loadingCallback.bind(this)}
                    clickedEquipment={this.state.clickedEquipment}
                    small ={this.state.small}
                    equipmentCallback={this.equipmentCallback.bind(this)}
                    active={this.state.active}/>
                <MainContentComponent
                    clickedEquipment={this.state.clickedEquipment}
                    loadingCallback = {this.loadingCallback.bind(this)}
                    activeCallback={this.activeCallback.bind(this)}
                    leafletCallback={this.leafletCallback.bind(this)}/>
                <SimmaoMapComponent small={this.state.small} equipmentCallback={this.equipmentCallback.bind(this)} style={{zIndex: 300}}>
                    <MooringLinesComponent equipmentCallback={this.equipmentCallback.bind(this)}/>
                    <SubseaObservatoryComponent equipmentCallback={this.equipmentCallback.bind(this)}/>
                </SimmaoMapComponent>
                {this.state.loading != false &&
                    <LoadingComponent/>
                }
            </div>
        );
    }
}

export default class EquipmentList extends Reflux.Component {
    render(){
        return (
            <div>
                <Header />
                <PanelContent />
                <PlotModal key={_.uniqueId('plot-modal-')}/>
                <PlotSel24hModal key={_.uniqueId('plot-modal-')}/>
            </div>
        );
    }
}
