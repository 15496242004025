import React from 'react';
import Reflux from 'reflux';
import _ from 'lodash';
import L from 'leaflet';
import leafletKnn from 'leaflet-knn';
import Control from "react-leaflet-control";
import {
    Map,
    TileLayer,
    Marker,
} from 'react-leaflet';
import NmScale from "@marfle/react-leaflet-nmscale";
import {LoadingComponent} from './Panel';

// Map Store
import {MapStore} from '../../store';
import {MapActions} from '../../actions';


// Styles
import styles from './Panel.css';
import twist_logo from '../twist_header/img/logo_twist_azul.png';


// Components

export default class SimmaoMapComponent extends Reflux.Component {
    constructor(props, context){
        super(props, context);
        this.store= MapStore;
        this.center = [-25.4928, -43.7917];
        this.state = {
            position: null,
            loading: false,
            viewport: {
                center: this.center,
                zoom: this.props.small ==false ? 7 : 6
            }
        };
    }


    componentDidMount() {
        this.mapApi = this.refs.map.leafletElement;
        
        if(this.props.modal == true){    
            this.mapApi.on('click', function(e){
                if(this.state.rasterAPI == 'traffic')
                    return
                this.setState({position: L.latLng(e.latlng.lat, e.latlng.lng), loading: true});
                window.setTimeout(function(){
                    if(this.state.mapOption == 'path'){
                        let template = _.cloneDeep(this.state.pablo[0].path);
                        template.features[0].geometry.coordinates = [];
                        this.state.pablo.map(data =>{
                            for(let i = 0;i < data.path.features[0].geometry.coordinates.length; i++)
                                template.features[0].geometry.coordinates.push(data.path.features[0].geometry.coordinates[i]);
                        });
                        var gj = L.geoJson(template);
                        var nearest = leafletKnn(gj).nearest(e.latlng, 1);
                        this.setState({position: L.latLng(nearest[0].lat, nearest[0].lon), loading: true});
                        MapActions.showModal(nearest[0].lat, nearest[0].lon);
                    }else{
                        MapActions.showModal(e.latlng.lat, e.latlng.lng);
                    }
                    
                    this.setState({loading: false});
                }.bind(this), 500);
            }.bind(this));

            MapActions.storeMap(this.mapApi);
        }
    }

    componentWillReceiveProps(){
        this.mapApi.invalidateSize();
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.small !== prevProps.small)
            this.setState({viewport: {center: this.center, zoom:this.props.small ==false ? 7 : 6}})
    }

    render (){
        let map =   <Map
                    ref='map'
                    // center={position} id='map'
                    id='map'
                    viewport={this.state.viewport}
                    key='map' minZoom={4}
                    zoomAnimation={false}
                    className={(this.props.small == false ? styles.leafletContainer : styles.smallLeafletContainer) + ' ' + (this.props.modal == true ? styles.leafletModal : '')}>
                    <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors | 
                        Derived product from the <a href=&quot;https://www.gebco.net/data_and_products/gridded_bathymetry_data/&quot;>GEBCO 2019 Grid</a>, made with <a href=&quot;https://www.naturalearthdata.com/&quot;>NaturalEarth</a> by <a href=&quot;https://opendem.info/&quot;>OpenDEM</a>"
                        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                        // url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
                    />
                    <Control position="bottomright" >
                        <img className={styles.logotwist} src={twist_logo} />
                    </Control>
                    {this.props.children}
                        
                    {this.state.position != null &&
                        <Marker key={_.uniqueId('marker_')} position={this.state.position}/>
                    }

                    { !this.props.small &&
                        <Control position="topleft" >
                            <button className={styles.resetMapButton} 
                                onClick={() => this.setState({viewport: {center: this.center, zoom:this.props.small ==false ? 7 : 6}})}>
                                <i className="fas fa-undo-alt"></i>
                            </button>
                        </Control>
                    }
                    <NmScale metric={true}/>
                </Map>

        if(this.props.modal == true){
            map = <div className={styles.mapContainer}>
                {map}
                {this.state.loading != false &&
                    <LoadingComponent/>
                }
            </div>
        }
        return map;
    


    }
}

