import React from 'react';
import Reflux from 'reflux';
import { Redirect } from 'react-router-dom'

import { Panel,
    Col,
    Row,
} from 'react-bootstrap';

import Header from '../twist_header/Header';
import styles from './Verify.css';
import {decryptData, validate} from '../../crypto.js'
import {AuthStore} from '../../store.js';
import API from '../../api'

class Verify extends Reflux.Component {

    constructor(props) {
        super(props);
        this.key = props.match.params.key;
        this.store = AuthStore;
        this.api = new API();

    }

    handleLogin() {
        window.setTimeout(function(){
            this.props.history.push('/');
        }.bind(this), 3000);
    }

    render(){
        if (this.state.loggedin === true || validate(decryptData(localStorage.token))){
            return <Redirect to='/panel' />
        }

        var response = this.api.verify_email(this.key)       
        if(response.status === 200){
           this.handleLogin();
            return (
                <div>
                    {/* TODO: Add this to translations */}
                    <Header />
                    <Row>
                        <Col sm={6} smOffset={6} md={4} mdOffset={4} className={styles.loginForm}>
                            <Panel bsStyle="default">
                                <Panel.Heading  className={styles.headerForm}>
                                    <Panel.Title>
                                        <h3>E-mail verificado com sucesso</h3>
                                    </Panel.Title>
                                </Panel.Heading>
                                <Panel.Body>
                                    <p>Seu e-mail foi verificado com sucesso.</p>
                                    <p>Você será redirecionado à página de login</p>
                                </Panel.Body>
                            </Panel>
                        </Col>
                    </Row>
                </div>
            );
        } else{
            return (
                <div>
                    <Header />
                    <Row>
                        <Col sm={6} smOffset={6} md={4} mdOffset={4} className={styles.loginForm}>
                            <Panel bsStyle="default">
                                <Panel.Heading  className={styles.headerForm}>
                                    <Panel.Title>
                                        <h3>E-mail não verificado</h3>
                                    </Panel.Title>
                                </Panel.Heading>
                                <Panel.Body>
                                    <p>Não foi possível verificar seu e-mail.</p>
                                    <p>Por favor entre em contato com o administrador do sistema.</p>
                                </Panel.Body>
                            </Panel>
                        </Col>
                    </Row>
                </div>
            );
        }
    }
}


export default Verify;
