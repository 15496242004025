import React from 'react';
import Reflux from 'reflux';
import { Redirect } from 'react-router-dom'

import { Panel,
    Col,
    Row,
    FormGroup,
    FormControl,
    InputGroup,
    Alert as BSAlert
} from 'react-bootstrap';

import { Button, Alert, Icon } from 'antd';

import Header from '../twist_header/Header';
import styles from './Login.css';
import {AuthStore} from '../../store.js';
import {AuthActions} from '../../actions.js';
import {decryptData, validate} from '../../crypto.js'


class ErrorMessage extends Reflux.Component{
    constructor(props){
        super(props);
        this.store = AuthStore;
        this.state = {loading: false};
    }

    handleDismiss(key) {
        console.log(key);
        let _error = this.state.error;
        delete _error[key];
        this.setState({
            error: _error
        });
    }

    componentDidMount(){
        this.setState({
            error: '', loading: false
        });
    }

    render(){
        let errorMessage = [];
        if (this.state.error) {
            for(var key in this.state.error){
                errorMessage.push(
                    <Alert key={_.uniqueId('error_')} message={this.state.error[key]} type="error" afterClose={this.handleDismiss.bind(this,key)} closable style={{marginBottom: 10}} />

                );
            }
        }

        return errorMessage;
    }
}

class Login extends Reflux.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleRegistration = this.handleRegistration.bind(this);
        this.store = AuthStore;
        this.state = {
            email: '',
            password: '',
            loading: false
        };
    }
    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});
        try {
            AuthActions.login(
                this.state.email,
                this.state.password
            );
        } catch (e) {
            console.log('Login error')
        }
    }

    handleRegistration() {
        this.props.history.push('/registration');
    }

    handleReset() {
        this.props.history.push('/password-reset');
    }

    render(){
        if (this.state.loggedin === true || validate(decryptData(localStorage.token))){
            return <Redirect to='/panel' />
        }

        return (
            <div>
                <Header />
                <Row>
                    <Col sm={6} smOffset={6} md={4} mdOffset={4}>
                        <BSAlert>
                            <p>A realização do PMPAS-BS é uma medida de
            monitoramento exigida pelo licenciamento ambiental federal,
            conduzido pelo IBAMA.</p>
                        </BSAlert>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} smOffset={6} md={4} mdOffset={4} className={styles.loginForm}>
                        <Panel bsStyle="default" className={styles.panel}>
                            <Panel.Heading  className={styles.headerForm}>
                                <Panel.Title>
                                    <div className={styles.headerIcon}><Icon type="user"/></div>
                                    <h3>{'Login'}</h3>
                                </Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <ErrorMessage />
                                <form onSubmit={this.handleSubmit}>
                                    <FormGroup controlId="email" bsSize="large">
                                        <InputGroup>
                                            <InputGroup.Addon style={{backgroundColor: 'white'}}>
                                                <Icon type="mail" />
                                            </InputGroup.Addon>
                                                <FormControl
                                                    autoFocus
                                                    type="email"
                                                    placeholder={"E-mail"}
                                                    value={this.state.email}
                                                    onChange={this.handleChange}
                                                />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup controlId="password" bsSize="large">
                                        <InputGroup>
                                            <InputGroup.Addon style={{backgroundColor: 'white'}}>
                                                <Icon type="lock" />
                                            </InputGroup.Addon>
                                            <FormControl
                                                placeholder={"Senha | Password"}
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                                type="password"
                                                />
                                        </InputGroup>
                                    </FormGroup>

                                    <div className={styles.link + ' ' + styles.forgotPwd}>
                                        <a onClick={this.handleReset}> {'Esqueceu sua senha? | Forgot your password?'}</a>
                                    </div>
                                    <Button type="primary" className={styles.submitButton + ' ' + (!this.validateForm() ? styles.disabled : '') } loading={this.state.loading} htmlType={'submit'}> {'Entrar | Sign in'} </Button>
                                </form>
                            </Panel.Body>
                        </Panel>
                        <div className={styles.link}>
                            <span>{'Não possui conta?'}</span>
                            <a onClick={this.handleRegistration}> {'Cadastre-se aqui'}</a>
                            <br></br>
                            <span>{'Don\'t have an account?'}</span>
                            <a onClick={this.handleRegistration}> {'Register here'}</a>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export {Login, ErrorMessage}
