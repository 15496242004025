import React from 'react';
import Reflux from 'reflux';

import {
    Modal, Button, Tabs, Tab
} from 'react-bootstrap';

import Plot from 'react-plotly.js';
import {MapStore} from '../../store';
import {MapActions} from '../../actions';
import _ from 'lodash';

import dateformat  from 'dateformat';
import fileDownload from 'js-file-download';

import { Table, Select } from 'antd';
const Option = Select.Option;
import {decryptData} from '../../crypto'


const terms =  'Estes dados ostensivos podem ser apenas utilizados para finalidades científicas. É proibida a utilização para quaisquer aplicações comerciais ou de cunho empresarial. O uso inadequado sujeitará o usuário e sua empresa ou instituição às ações jurídicas cabíveis.';

class SpectralChart extends Reflux.Component {
    constructor(props, context){
        super(props, context);
        this.state = {
            name: props.data.name,
            data: this.renderData(props.data),
            layout:{
                xaxis: { type: 'log', autorange: false, range: [Math.log10(5), Math.log10(65000)], title: 'Frequência (Hz)' },
                yaxis: { title: 'SPL (dB ref a 1µPa²)' },
                width: 850,
                height: window.innerHeight < 800 ? 380 : null, 
                legend: {
                    orientation: 'h',
                    traceorder:'reversed',
                    x: -.1,
                    y: 1.2 },
                title: props.data},
            config: {modeBarButtonsToRemove: ['toImage', 'sendDataToCloud', 'zoom2d', 'pan2d', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toggleSpikelines'], displaylogo: false }
        };
    }
    renderData( data , opt ) {
        opt = _.extend({}, { type: 'scatter', mode: 'lines', }, opt||{});
        let new_data = [];
        _.each(data, function(plt){
            new_data.push(_.extend({}, plt, opt));
        });
        return new_data;
    }
    render(){
        return  (<Plot data={this.state.data}
            config={this.state.config}
            layout={this.state.layout} />);
    }
}

class TwelveSpectralChart extends Reflux.Component {
    constructor(props, context){
        super(props, context);
        this.state = {
            name: "Média",
            data: [{ type: 'scatter', mode: 'lines', x: this.props.data[0].x, y:this.props.data[0].y}],
            layout:{
                xaxis: { type: 'log', autorange: false, range: [Math.log10(5), Math.log10(65000)], title: 'Frequência (Hz)' },
                yaxis: { title: 'SPL (dB ref a 1µPa²)' },
                width: 850,
                legend: {
                    orientation: 'h',
                    traceorder:'reversed',
                    x: -.1,
                    y: 1.2 
                },
            },
            config: {modeBarButtonsToRemove: ['toImage', 'sendDataToCloud', 'zoom2d', 'pan2d', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toggleSpikelines'], displaylogo: false }
        };
    }
    render(){
        return  (<Plot data={this.state.data}
            config={this.state.config}
            layout={this.state.layout} />);
    }
}

class TSProfileChart extends Reflux.Component {
    constructor(props, context){
        super(props, context);
        this.temp = {
            x: props.data.temp,
            y: props.data.depth.map(a => a*-1),
            name: 'Temperatura',
            type: 'scatter'
        }
        this.salin= {
            x: props.data.salin,
            y: props.data.depth.map(a => a*-1),
            name: 'Salinidade',
            xaxis: 'x2',
            type: 'scatter'
        }
        this.state = {
            name: "Média",
            data: props.data.salin ? [this.salin, this.temp] : [this.temp],
            layout:{
                xaxis: { title: 'Temperatura (graus)',showspikes: true, range: [0, 30] },
                xaxis2: { title: 'Salinidade (ppm)' ,side: "top", overlaying: "x", showspikes: true, range: [30, 37] },
                yaxis: { title: 'Profundidade (m)', range: [-1000, 0] },
                width: 850,
                legend: {
                    orientation: 'h',
                    traceorder:'reversed',
                    x: -.1,
                    y: 1.2 
                },
                hovermode: 'y unified'
            },
            config: {modeBarButtonsToRemove: ['toImage', 'sendDataToCloud', 'zoom2d', 'pan2d', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toggleSpikelines'], displaylogo: false }
        };
    }
    render(){
        return  (<Plot data={this.state.data}
            config={this.state.config}
            layout={this.state.layout} />);
    }
}

export default class PlotPathModal extends Reflux.Component {

    constructor(props){
        super(props);
        this.state = {showTerms: false, loading: false};
        this.store = MapStore;
        this.storeKeys = [
            'lat',
            'lng',
            'modalPlotElement',
            'plot_data',
            'showModal',
            'animateModal'
        ];
        this.columns = [{
            title: 'Modelo',
            dataIndex: 'model',
            key: 'model',
            align: 'center'
          },{
            title: 'N.º de série',
            dataIndex: 'serial_number',
            key: 'serial_number',
            align: 'center'
          },{
            title: 'Campanha',
            dataIndex: 'campaign',
            key: 'campaign',
            align: 'center'
          }, {
            title: 'Leitura',
            dataIndex: 'reading',
            key: 'reading',
          }, {
            title: 'Profundidades',
            dataIndex: 'depths',
            key: 'depths',
            render: depths => {
                if(depths == null) return '-';
                return(
                    <Select defaultValue={this.state.plot_data[0]['depth']} style={{ width: 100 }} 
                        key={_.uniqueId('option')}
                        loading={this.state.loading}
                        onChange={value => {
                            this.setState({loading: true})
                            setTimeout(function(){
                                MapActions.getSpectrumData(this.store.state.lat, this.store.state.lng, value)
                                this.setState({loading: false});
                            }.bind(this), 500)
                        }}
                    >
                        {depths.map(depth => {
                            return <Option key={_.uniqueId('option')} value={depth}>{depth.toFixed(1)+'m'}</Option>
                        })}
                    </Select>
                )
            }
          }, {
            title: 'Latitude',
            key: 'lat',
            dataIndex: 'lat',
          }, {
            title: 'Longitude',
            key: 'lng',
            dataIndex: 'lng',
        }, {
            title: 'Temperatura',
            key: 'temperature',
            dataIndex: 'temperature',
        }];

        this.ts_profile_permission = JSON.parse(decryptData(localStorage.getItem('permissions'))).includes('view_ts_profile');

    }
    close(){
        MapActions.hideModal();
        this.setState({plot_data: null, animateModal: true});
    }

    showTerms(terms){
        this.setState({showTerms: terms, showModal: !terms, animateModal: true});        
    }
    
    downloadData(){
        let _data = this.state.plot_data;
        delete _data[0]["ts_profile"];
        fileDownload(JSON.stringify(_data), 'data.json');
        this.showTerms(false);
    }

    componentDidMount(){
        if (this.state.showModal && _.isEmpty(this.state.plot_data)){
            MapActions.getSpectrumData(this.store.state.lat,
                                       this.store.state.lng, undefined,true);
        }
    }

    render() {
        let name = null;
        let data = null;
        let reading = null;
        let final_reading = null;
        let temperature = null;
        let lat = null;
        let lng = null;
        let campaign = null;
        let model = null;
        let serial_number = null;
        let spectrum_twelve_data = null;
        let ts_profile_data = null;
        if (!this.state.plot_data)
            return null;

        // Plot
        data = this.state.plot_data[0];
        spectrum_twelve_data = data['spectrum_12'];
        ts_profile_data = data['ts_profile'] ? data['ts_profile'] : null;
        reading = data['initial_datetime'];
        final_reading = data['final_datetime'];
        temperature = data['temperature'];
        lat = data['lat'];
        lng = data['long'];
        campaign = data['campaign'];
        model = data['eq_model'];
        serial_number = data['eq_serial'];
        name = data['eq_system'];

        let reading_dt = new Date(reading);
        let final_reading_dt = new Date(final_reading);

        let  tableData = [{
            key: _.uniqueId('row-'),
            campaign: campaign,
            model: model,
            serial_number: serial_number,
            reading: dateformat(reading_dt, 'dd/mm/yyyy h:MM:ss') + ' a ' + dateformat(final_reading_dt, 'h:MM:ss'),
            temperature: temperature == null ? '-' : temperature.toFixed(1)+'ºC',
            depths: data['depths'],
            lat: lat,
            lng: lng,
          }];
        data = data['spectrum']
        

        return (
            <div>
                <Modal
                    show={this.state.showModal}
                    bsSize="large"
                    aria-labelledby="contained-modal-title-vcenter"
                    key={this.state.modalPlotElement}
                    animation={this.state.animateModal}
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-lg">{name + ' - '+ this.state.modalPlotElement}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table dataSource={tableData} columns={this.columns} pagination={false} />
                        <Tabs defaultActiveKey={0} id={_.uniqueId("spl_tab-")}>
                            <Tab eventKey={0} key={0} title={'⅓ oitava'}>
                                <SpectralChart
                                    element={this.state.modalPlotElement}
                                    data={data}
                                />
                            </Tab>
                            { model != "PABLO" &&
                                <Tab eventKey={1} key={1} title={'1⁄12 oitava'} disabled={spectrum_twelve_data.length == 0}>
                                    {   spectrum_twelve_data.length &&
                                        <TwelveSpectralChart
                                            element={this.state.modalPlotElement}
                                            data={spectrum_twelve_data}
                                        />

                                    }
                                </Tab>
                            }
                            { this.ts_profile_permission &&
                                <Tab eventKey={2} key={2} title={'Perfil TS'} disabled={!ts_profile_data}>
                                    { ts_profile_data &&
                                        <TSProfileChart
                                        element={this.state.modalPlotElement}
                                        data={ts_profile_data}
                                        />

                                    }    
                                </Tab>
                            }
                        </Tabs>  
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.showTerms.bind(this, true)}>{'Baixar Dados'}</Button>
                        <Button onClick={this.close.bind(this)}>{'Fechar'}</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showTerms}>
                    <Modal.Body>
                        {terms}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.downloadData.bind(this)}>{'Aceitar'}</Button>
                        <Button onClick={this.showTerms.bind(this, false)}>{'Fechar'}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export {PlotPathModal, SpectralChart, TwelveSpectralChart}
