import React from 'react';
import Reflux from 'reflux';

import {MapStore} from '../../store';

import {GeoJSON} from 'react-leaflet';

import Control from 'react-leaflet-control';
import {FeatureGroup} from 'react-leaflet';


const pablo_style = {color: '#0c2b4f', weight: 1.5, opacity: 0.5, lineJoin: 'round'};

import {pablo_icon, glider_icon} from './icons';

class RasterComponent extends Reflux.Component {
    constructor(props, context){
        super(props, context);
        this.state = {'layer': null};
        this.store = MapStore;
        this.storeKeys = ['mapOption','rasterAPI', 'rasterData'];
        this.component = null;
        this.colorbar = null;
        this.geojson = null;
    }
    getUrl(b64img){
        return `data:image/jpeg;base64,${b64img}`;
    }

    get_style(feature){
        return feature.properties.style ;
    }

    render(){
        if (this.store.state.rasterData && this.store.state.mapOption != 'path')
            return(<div>
                <Control position="topleft">
                    <img src={this.getUrl(this.store.state.rasterData.colorbar) } />
                </Control>
                <GeoJSON
                    key={_.uniqueId('rasterData_')}
                    id="raster_vector_overlay"
                    data={this.store.state.rasterData.geojson}
                    style={this.get_style}
                />
            </div>);
        return null;
    }
}

class EquipmentPathComponent extends Reflux.Component {
    constructor(props, context){
        super(props, context);
        this.store= MapStore;
    }

    pointToLayer(feature, latlng){
        let icon = feature.properties.equipment_system == 'Perfilador'? pablo_icon : glider_icon;
        const marker =  L.marker(latlng, {icon: icon});
        return marker;
    }

    render(){
        if (this.store.state.pablo !== null && this.store.state.mapOption=='path'){
            const pablo_data = this.store.state.pablo;
            return (<FeatureGroup>{ _.keys(pablo_data).map((idx) => 
                        <GeoJSON key={_.uniqueId('path_')}
                            id={pablo_data[idx].path.features[0].properties.code}
                            name={pablo_data[idx].name}
                            campaign={pablo_data[idx].path.features[0].properties.campaign}
                            pointToLayer={this.pointToLayer.bind(this)}
                            data={pablo_data[idx].path}
                            style={pablo_style} 
                        />)}
                    </FeatureGroup>
            );
        }
        return null;
    }
}

export {RasterComponent, EquipmentPathComponent}