import React from 'react';
import Reflux from 'reflux';


import {GeoJSON} from 'react-leaflet';
import L, { LatLng } from 'leaflet';

import _ from 'lodash';
import $ from 'jquery';

// Static Data
import bathymetry_data from './bathymetry.json';
import agbs_data from './agbs.json';
import bay_data from './bay.json';
import ducts_data from './ducts.json';
import platforms_data from './platforms.json';
import ldf_data from './ldf.json';
import sso_data from './sso.json';
import fiberoptics_data from './fiberoptics.json';
import pipeline_data from './pipeline.json';
import flexibleducts_data from './flexibleducts.json';

// Icons
import {platform_icon, mooring_icon, sso_icon} from './icons';

const bathymetry_style = { color: '#808080', weight: 1, opacity: 0.35 };
const agbs_style = { color: '#FD9108', weight: 3, opacity: .85 };
const bay_style = { color: '#0080FF', weight: 3, opacity: .85 };
const ducts_style = { color: '#b7484b', weight: 1, opacity: .85 };
const flexibleducts_style = { color: '#FD9108', weight: 1, opacity: .85 };
const fiberoptics_style = { color: '#987db7', weight: 1, opacity: .95 };
const pipeline_style = { color: '#1e5c9c', weight: 1, opacity: .95 };
const platforms_style = { color: '#222' };
const ldf_style = { color: '#CC0000' };
const sso_style = { color: '#222' };

// Components
class PlatformsComponent extends Reflux.Component {
    pointToLayer(feature, latlng){
        const marker =  L.marker(latlng, {icon: platform_icon});
        // const marker =  L.circleMarker(latlng, {radius:4, fillColor: '#becf50', weight: 1, fillOpacity: 1});
        return marker;
    }

    onmouseover(feature, layer) {
        const platformType = feature.properties.NOME_PLATA;
        layer.on('mouseover', function(e) {
            layer.bindPopup(platformType)
        });
    }

    render(){
        return <GeoJSON
            key={_.uniqueId('platforms_')}
            id='platforms'
            data={platforms_data}
            style={platforms_style}
            onEachFeature={this.onmouseover.bind(this)}
            pointToLayer={this.pointToLayer.bind(this)}
        />;
    }
}

class MooringLinesComponent extends Reflux.Component {
    pointToLayer(feature, latlng){
        // const marker =  L.circleMarker(latlng, {radius:4, fillColor: '#e15989', weight: 1, fillOpacity: 1});
        const marker =  L.marker(latlng, {icon: mooring_icon});
        return marker;
    }
    render(){
        return <GeoJSON
            key={_.uniqueId('ldf_')}
            id='df'
            data={ldf_data}
            style={ldf_style}
            pointToLayer={this.pointToLayer.bind(this)}
        />;
    }
}


class SubseaObservatoryComponent extends Reflux.Component {
    pointToLayer(feature, latlng){
        const marker =  L.marker(latlng, {icon: sso_icon});
        // const marker =  L.circleMarker(latlng, {radius:4, fillColor: '#987db7', weight: 1, fillOpacity: 1}); 
        return marker;
    }
    render(){
        return <GeoJSON
            key={_.uniqueId('sso_')}
            id='sso'
            data={sso_data}
            style={sso_style}
            pointToLayer={this.pointToLayer.bind(this)}
        />;
    }
}


class DuctsComponent extends Reflux.Component {
    render(){
        return <GeoJSON
            key={_.uniqueId('ducts_')}
            id='ducts'
            data={ducts_data}
            style={ducts_style}
        />;
    }
}

class PipelineComponent extends Reflux.Component {
    render(){
        return <GeoJSON
            key={_.uniqueId('ducts_')}
            id='ducts'
            data={pipeline_data}
            style={pipeline_style}
        />;
    }
}

class FlexibleDuctsComponent extends Reflux.Component {
    render(){
        return <GeoJSON
            key={_.uniqueId('flexibleducts_')}
            id='flexibleducts'
            data={flexibleducts_data}
            style={flexibleducts_style}
        />;
    }
}

class FiberOpticsComponent extends Reflux.Component {
    render(){
        return <GeoJSON
            key={_.uniqueId('fiberoptics_')}
            id='fiberoptics'
            data={fiberoptics_data}
            style={fiberoptics_style}
        />;
    }
}


class AGBSComponent extends Reflux.Component {
    render(){
        return <GeoJSON
            key={_.uniqueId('agbs_')}
            id='agbs'
            data={agbs_data}
            style={agbs_style}
        />;
    }
}

class BayComponent extends Reflux.Component {
    render(){
        return <GeoJSON
            key={_.uniqueId('bay_')}
            id='agbs'
            data={bay_data}
            style={bay_style}
        />;
    }
}


class BathymetryComponent extends Reflux.Component {
    render(){
        return <GeoJSON
            key={_.uniqueId('bathmetry_')}
            id='bathmetry'
            data={bathymetry_data}
            style={bathymetry_style}
        />;
    }
}

export {BathymetryComponent, AGBSComponent, BayComponent, DuctsComponent, PipelineComponent, FlexibleDuctsComponent, FiberOpticsComponent, SubseaObservatoryComponent, MooringLinesComponent, PlatformsComponent }